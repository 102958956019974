.steps {
  padding: 20px;
  display: flex;
  justify-content: space-between;

  &-title {
    padding-left: 20px;
    margin-top: 40px;
  }

  & > .step-container {
    flex: 1;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    margin-top: 20px;
  }
}

.step-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  max-width: 185px;
}

.step {

  &-icon {
    min-width: 96px;
    max-width: 96px;
    height: 96px;

    @media (max-width: 768px) {
      min-width: 64px;
      max-width: 64px;
      height: 64px;
    }
  }

  &-title {
    color: #409DD9;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
  }

  &-description {
    color: #49546C;
    font-size: 16px;
    font-weight: 400;
    max-width: 150px;
  }

  &-submit-button {
    border-radius: 60px;
    background: #EE3F58;
    display: flex;
    width: 100%;
    padding: 10px;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    border: none;
    outline: none;
    color: #FFF;
  }

  &-title, &-description {
    margin: 0;
    text-align: center;
    line-height: normal;
  }
}

.arrow {
  min-width: 27px;
  max-height: 12px;
  margin: 42px auto 0;

  @media (max-width: 768px) {
    display: none;
  }
}