label {
  font-size: 14px;
  font-weight: 400;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  transition:.3s;
}

textarea {
  overflow: auto;
  resize: vertical;
  width: 100%;
  border: none;
  background: #f5f5f5;
  margin-bottom: 24px;
  padding: 16px 20px;
  min-height: 32px;
}

input:not([type]),
input[type=date],
input[type=datetime-local],
input[type=email],
input[type=file],
input[type=number],
input[type=password],
input[type=search],
input[type=tel],
input[type="month"],
input[type=week],
input[type=text],
input[type=time],
input[type=url],
textarea,
select {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 100%;
  margin: 0;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 10px 14px;
  background: transparent;
  border: 1px solid #fff;
  color: #FFFFFF;
  box-shadow: none;
  border-radius: 3px;
}

button[type=submit] {
  padding: 10px 14px;
}

:-moz-placeholder,
::-moz-placeholder,
:-ms-input-placeholder,
::placeholder {
  color: #feb9c3;
  font-size: 14px;
}

::-webkit-input-placeholder {
  color: #feb9c3;
}

::-moz-selection,
::selection {
  background: #b3d4fc;
  text-shadow: none;
}

.info-box span,
.info-box span i {
  display: inline-block;
}

.tel-form .wrapp-form {
  max-width: 250px;
  margin: 0 auto;
  text-align: center;
}

.form-group {
  margin: 0 0 22px;

  &.field-getexpertconsultationform-recaptcha,
  &.field-getdetailsconsultationform-recaptcha {
    height: 74px;
    margin-left: -29px;
    transform: scale(0.78);

    @media (max-width: 360px) {
      margin-left: -40px;
      transform: scale(.67);
    }
  }
}

.help-block {
  display: block;
  color: #669cd6;
  font-size: 12px;
}
