.header-banner {
  background: #fff;

  .wrapper {
    position: relative;

    &::after {
      @media screen and(min-width:992px) {
        content: url('/img/lines-add.png');
        position: absolute;
        right: 0;
        z-index: 1;
        top: 0;
      }
    }

    &::before {
      content: url('/img/lines.png');
      position: absolute;
      left: 8%;
      z-index: 1;
    }
  }

  .banner {
    background: linear-gradient(90deg, #df4a67 0%, #7991c6 100%);
    height: 90px;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;

    &:before {
      content: url('/img/back2.png');
      position: absolute;
      top: 0;
    }

    .banner-partner {
      background: url('/img/back.png');
      position: relative;
      left: 4px;
      top: -10px;
      width: 266px;
      height: 90px;

      &::after {
        content: url('/img/stars.png');
        position: absolute;
        left: 25px;
        top: 25px;
      }

      &__title {
        color: #f4f4f4;
        font-weight: 700;
        text-align: right;
        margin: 0;
        position: absolute;
        top: 20px;
        left: 15px;
        font-size: 11px;
      }

      &__img {
        position: absolute;
        bottom: 10px;
        right: 60px;
        z-index: 9;
      }
    }

    .banner-offer {
      display: flex;
      gap: 35px;
      align-items: center;
      margin-left: 35px;

      @media screen and(max-width:991px) {
        display: none;
      }

      &__title {
        color: rgba(244, 244, 244, 0.71);
        font-size: 15px;
        text-align: right;
      }

      p {
        font-size: 23px;
        color: #f4f4f4;
        margin: 0;
        font-family: 'Russo One', sans-serif;

        @media screen and(min-width:992px) and(max-width:1199px) {
          font-size: 20px;
        }

        .banner-btn {
          background: #f4f4f4;
          border-radius: 16.48px;
          font-size: 9px;
          font-weight: 700;
          padding: 7px 10px;
          color: #424242;
          text-transform: uppercase;
          margin-left: 10px;
          z-index: 9;
          position: relative;
          line-height: 1;
          border: none;

          span {
            font-size: 8px;
            color: #424242;
            font-weight: 700;

            &:first-child {
              margin-left: 5px;
            }

            &:last-child {
              margin-left: -5px;
            }
          }
        }
      }
    }
  }
}
