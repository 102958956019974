.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding: 0 2px;
  margin-top: 20px;

  @media (max-width: 767px) {
    padding: 16px 0 10px;
    flex-wrap: wrap;
    overflow: auto;
    height: 100%;
  }

  > li {
    span {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
    }

    @media (max-width: 767px) {
      display: inline;
      white-space: normal;
    }
  }

  li:last-child a {
    color: #EF4F67;
  }
}

.breadcrumb .active {
  color: #e7445e;
}

.breadcrumb li::before {
  content: '\003E';
  display: inline-block;
  color: #1293d4;
  margin-right: 2px;
  margin-left: 3px;
}

.breadcrumb > li + li::after,
.breadcrumb li:first-child::before {
  display: none;
}
