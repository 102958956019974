.team-item {
  background: #6099C9;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  max-width: 248px;
  height: 390px;
  margin: 25px 0;

  @media screen and (max-width: 767px) {
    max-width: 340px;
    width: 100%;
    margin: 15px 0;
  }

  &__image {
    overflow: hidden;
    height: 282px;
    display: flex;
    justify-content: center;
  }

  &__image img {
    max-width: initial;
    scale: 1.2;
  }

  &__description {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    justify-items: center;
    height: 101px;
    padding: 0 12px;

    @media screen and (max-width: 767px) {
      padding: 8px 50px;
    }
  }

  &__name, &__position {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    display: flex;
    justify-content: center;
    color: #fff;
  }

  @media (max-width: 768px) {
  }
}

#team-member-page {
  background: #fff;
  margin-top: 20px;
  padding: 32px 20px 21px 20px;

  .team-member-page__container {
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 767px) {
      flex-direction: column;
    }

    &__image {
      overflow: hidden;
      height: 482px;
      width: 442px;
      display: flex;
      justify-content: center;

      @media screen and (max-width: 767px) {
        height: 227px;
        width: auto;
      }

      img {
        max-width: initial;
      }
    }

    &__content {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      margin-left: 20px;
      width: 58%;

      @media screen and (max-width: 767px) {
        width: 100%;
        margin: 0;
      }

      &__head {
        height: 94px;

        @media screen and (max-width: 767px) {
          margin: 10px 0;
          height: 80px;
        }

        &-content {
          display: flex;
          justify-content: space-around;
          flex-direction: column;
          text-align: center;
          color: #FFFFFF;
          line-height: 28px;
          background: #6099C9;
          transform: skew(-25deg);
          margin: 0 22px;
          height: 100%;
          padding-top: 10px;

          @media screen and (max-width: 767px) {
            padding: 15px 0;
          }

          .team-member__name {
            font-weight: 700;
            transform: skew(25deg);
            font-size: 24px;

            @media screen and (max-width: 767px) {
              font-size: 18px;
            }
          }

          .team-member__position {
            font-weight: 500;
            transform: skew(25deg);
            font-size: 24px;
            font-family: Roboto serif;

            @media screen and (max-width: 767px) {
              font-size: 18px;
            }
          }
        }
      }

      &__feature {
        color: #000000;
        display: flex;
        flex-direction: column;
        text-align: left;

        @media screen and (max-width: 767px) {
          margin-top: 15px;
          text-align: center;
        }

        div {
          display: flex;

          @media screen and (max-width: 767px) {
            display: inline;
          }
        }

        &-name {
          font-weight: 700;
          font-size: 24px;
          border-bottom: 3px solid #f15169;
          padding-bottom: 20px;

          @media screen and (max-width: 767px) {
            font-size: 18px;
            padding-bottom: 10px;
          }
        }

        &-value {
          font-size: 20px;
          line-height: 23px;
          font-weight: 500;
          margin-top: 20px;

          @media screen and (max-width: 767px) {
            font-size: 16px;
          }
        }
      }
    }
  }

  .team-member-page__back-button {
    margin-top: 45px;

    @media screen and (max-width: 767px) {
      text-align: center;
      margin-top: 30px;
    }

    a {
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      color: #F15169;
    }
  }

  .team-member-page__articles {
    margin-top: 2rem;

    h2 {
      text-align: center;
    }

    li {
      display: flex;
      gap: 1rem;
      align-items: center;
      margin-top: 0.5rem;

      span {
        font-size: 1.3rem;
      }

      a {
        font-size: 1.3rem;

        &:hover {
          color: #097db7;
        }

        > h5 {
          margin: 0 !important;
        }
      }
    }
  }
}

.pagination {
  @media screen and (max-width: 767px) {
    font-size: 18px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}