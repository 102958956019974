.registry-search__block {
  padding-bottom: 70px;
}

.step-title {
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 41px;
  text-align: center;
}

.step-nav > .nav-link > .nav-link-layout {
  box-shadow: 0px 4px 4px 1px rgb(64, 157, 217);
  border-radius: 15px;
}

.step-nav > .nav-link > .link-heading {
  background: #FFFFFF;
  box-shadow: 0px 0px 4px 1px rgb(64, 157, 217);
  border-color: rgb(215, 223, 237);
  border-width: 1px;
}

.step-nav > .nav-link > .link-body {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #424242;
}

#registry-widget, #eacaudit-widget, #barcode-widget button.accordion {
  //color: #424242;
  //border-color: #424242;
}

.registry-search__block div:first-child .registry-module-block .accordion {
  border-radius: 10px 10px 0 0;
}

.registry-search__block .registry-module-block .accordion {
  border: 1px solid #21299a;
  color: #21299a;
  font-size: 28px;
  font-weight: 700;
  width: 100%;
  padding: 24px;
  text-align: left;
  display: flex;
  justify-content: space-between;
}

.registry-search__block .registry-module-block .accordion-panel {
  display: none;
  border: 1px solid #d7d7d7;
  background-color: #ffffff;
  padding: 32px 41px;
}

.registry-search__block .registry-module-block .accordion-panel input {
  height: 56px;
  border-radius: 10px;
  border: 1px solid #d7d7d7;
  background-color: #ffffff;
  color: #585858;
  font-size: 16px;
  line-height: 32px;
}

.registry-search__block .registry-module-block .accordion-panel input::placeholder {
  color: #585858;
  font-size: 16px;
  line-height: 32px;
}

.registry-search__block .registry-module-block .accordion-panel label {
  display: none;
}

.registry-search__block .registry-module-block .accordion-panel select {
  margin: 40px 0;
  height: 56px;
  border-radius: 10px 10px 0 0;
  border: 1px solid #d7d7d7;
  background-color: #ffffff;
  color: #585858;
  font-size: 16px;
  line-height: 32px;
}

.registry-search__block .registry-module-block .accordion-panel select option {
  color: #585858;
  font-size: 16px;
  line-height: 32px;
}

.registry-search__block .registry-module-block .accordion-panel .btn {
  max-width: 284px;
  padding: 12px 0;
}
