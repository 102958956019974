$text: #49546C;

.company-advantages {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.advantages-title {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  color: $text;
}

.advantages {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &-item-container {
    display: flex;
    gap: 16px;

    @media (max-width: 450px) {
      gap: 12px;
    }

    .advantages-icon {
      min-width: 48px;
      height: 48px;

      @media (max-width: 450px) {
        min-width: 38px;
        height: 38px;
      }
    }

    .advantages-text-wrapper {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 7px;
      color: $text;

      @media (max-width: 450px) {
        gap: 8px;
      }

      .subtitle {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
      }

      .text, .subtitle {
        color: $text;
        margin: 0;
        line-height: normal;
      }
    }
  }
}
