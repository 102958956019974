html, body {
  height: 100%;
  font-family: 'Roboto', sans-serif;
  background-color: #f4f4f4;
  margin: 0;
}

body {
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  color: #424242;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

*, :after, :before {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0 0 10px;
  font-weight: 700;
  text-transform: none;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  color: inherit;
  font-weight: inherit;

  &:hover {
    text-decoration: none;
  }
}

h1 a:hover {
  color: #1293d4;
}

.h2, h2 {
  font-size: 28px;

  @media (max-width: 767px) {
    font-size: 23px;
  }
}


.h3, h3 {
  font-size: 24px;
}

p {
  margin: 0 0 15px;
  color: #424242;
  font-size: 16px;
  line-height: 1.2em;
}

hr {
  margin: 20px 0;
  padding: 0;
  border-bottom: 1px solid #eceff8;
  border-top: 0;
}

em, i {
  font-style: italic;
}

b, strong {
  font-weight: 700;
}

big, .big {
  font-size: 110%;
  line-height: 180%;
}

dt {
  font-weight: 700;
}

span {
  font-weight: 400;
  font-size: 14px;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

a {
  transition: all 0.3s ease 0s;
  text-decoration: none;
  color: #1293d4;
  font-size: 14px;
}

a:hover {
  text-decoration: none;
  color: #1293d4;
}

a:active,
a:hover {
  outline: 0 none;
}

a,
.btn {
  transition: all 0.3s ease-out 0s;
}

a:focus,
.btn:focus {
  text-decoration: none;
  outline: none;
}

a,
button,
input {
  outline: medium none;
  color: #1293d4;
}

.title {
  font-weight: 700;
  font-size: 30px;
  line-height: 22px;
  color: #333333;
}

h3 a:hover,
.title a:hover {
  color: #1293d4;
}

h4 a:hover {
  color: #1293d4;
}

.mark, mark {
  background: #4fc1f0;
  color: #ffffff;
}

.btn {
  display: inline-block;
  text-align: center;
  border: none;
  outline: 0;
  border-radius: 20px;
  text-transform: uppercase;
  color: #fff;
  padding: 10px 14px;
  margin: 0;
  background-color: #ee3f58;

  &:hover,
  &:focus {
    color: #fff;
  }
}

.btn-white {
  background: #fff;
  border-radius: 20px;
  color: #ef3e58;
  border: 1px solid #fff;
  text-transform: uppercase;
  white-space: nowrap;

  &:hover {
    background: transparent;
    color: #fff;
  }
}

.btn-blue {
  background-color: #669cd6;

  &:active,
  &:focus,
  &:hover {
    color: #669cd6;
    background-color: #fff;
  }
}

iframe {
  max-width: 100%;
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer;
}

img, .img {
  max-width: 100%;
  height: auto;
}

.h1okno {
  font-size: 35px;
  color: #000;
  padding-left: 30px;
  position: relative;
  font-weight: 400;
  margin-bottom: 30px;
  margin-top: 20px;

  @media (max-width: 767px) {
    font-size: 25px;
    padding-left: 18px;
  }

  &:before {
    content: "";
    width: 4px;
    position: absolute;
    left: 0;
    top: 5px;
    bottom: 5px;
    background: #e85f77;
  }
}

blockquote {
  padding: 10px 20px;
  margin: 0 0 15px;
  border-left: 5px solid #e85f77;
}

body .container blockquote p {
  font-size: 17px;
}

.typo {
  &-main-color {
    color: var(--typo-main-color);
    &-parent * {
      color: var(--typo-main-color)!important;
    }
  }
  &-secondary-color {
    color: var(--typo-secondary-color);
  }
}

