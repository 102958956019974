.advantages-item {
  display: flex;
  flex-direction: row;
  background: #F4F4F4;
  width: 365px;
  margin: 0 100px 71px;

  @media screen and (max-width: 767px) {
    margin: 0 0 12px;
  }

  &__image {
    background: #FFFFFF;
    //border: 3px solid #4391CF;

    img {
      max-width: 115px;
    }
  }

  &__container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: #F4F4F4;
    padding: 0 10px;

    &__item-name {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #424242;
    }
  }
}