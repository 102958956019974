.mean-container a.meanmenu-reveal {
  color: #333333;
  cursor: pointer;
  display: inline-block;
  height: 42px;
  line-height: 22px;
  text-decoration: none;
  width: 54px;
  border-radius: 50%;
  position: absolute;
  left: auto !important;
  right: 0 !important;
  top: 0 !important;

  &:after,
  &:before,
  span {
    content: "";
    position: absolute;
    width: 30px;
    height: 4px;
    background: #848484;
    display: inline-block;
  }

  &:before {
    top: 11px;
    left: 12px;
  }

  &:after {
    bottom: 11px;
    left: 12px;
  }

  span {
    top: 19px;
    left: 12px;
  }
}

.mean-container .mean-nav {
  position: absolute;
  left: 0;
  right: 0;
  top: 62px;
  z-index: 8;
  width: 100%;
  overflow: hidden;
}

.mean-container .mean-nav ul {
  padding: 0;
  margin: 0;
  width: 100%;
  flex-wrap: wrap;
  background: linear-gradient(to right, rgb(222, 74, 103) 1%, rgb(119, 145, 199) 100%);

  li {
    position: relative;
    display: block;
    width: 100%;
  }
}

.mean-container .mean-nav ul li a {
  display: block;
  width: 100%;
  padding: 10px 15px;
  margin: 0;
  text-align: left;
  color: #fff;
  border-top: 1px solid #474747;
  text-decoration: none;
  text-transform: uppercase;
  background: rgba(255, 255, 255, 0.1);
}

.mean-container .mean-nav ul li:first-child a {
  border-top: 1px solid transparent;
}

.mean-container .mean-nav > ul > li a {
  position: relative;
}

.mean-container .mean-nav > ul > li a:before {
  content: "\e905";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  display: none;
  font-family: icomoon !important;
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

.mean-container .mean-nav > ul > li a:hover {
  text-decoration: underline;
  padding-left: 40px;
}

.mean-container .mean-nav > ul > li a:hover:before {
  display: inline-block;
  left: 12px;
}

.mean-container .mean-nav ul li li a {
  font-size: 12px !important;
  padding-left: 30px;
}

.mean-nav .wrapper {
  width: 100%;
  padding: 0;
  margin: 0;
}

.mean-remove {
  display: none !important;
}
