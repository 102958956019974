.short-code-replacer-hr {
  text-align: center;
  padding: 20px;
  overflow: hidden;

  .short-code-replacer-hr-button {
    position: relative;

    &:before,
    &:after {
      position: absolute;
      content: '';
      height: 1px;
      background-color: #49546c;
      width: 400px;
      top: 20px;
    }

    &:before {
      left: 110%;
    }

    &:after {
      right: 110%;
    }

  }
}
