.pagination {
  display: flex;
  flex-wrap: wrap;

  .first,
  .last {
    display: none;
  }

  li {
    display: flex;
    align-items: center;
    vertical-align: middle;
    font-size: 12px;
    padding: 0 12px;
    background-color: transparent;
  }

  .prev i,
  .next i {
    color: #669cd6;
    font-size: 12px;
  }

  .active a {
    color: #000;
    pointer-events: none;
  }
}
