$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "../fonts/icomoon/fonts" !default;

$icon-arrow-long: "\e905";
$icon-arrow-left: "\e902";
$icon-arrow-right: "\e903";
$icon-email: "\e904";
$icon-arrow-down: "\e901";
$icon-search: "\e900";

