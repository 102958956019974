.select-doc {
  padding: 14px;

  .h-title {
    display: block;
    margin: 25px 0 40px 0;
    color: #409DD9;
    font-size: 28px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
  }

  .hint {
    display: flex;
    align-items: center;
    padding: 35px 30px;
    border: 1px dashed #409DD9;
    margin-bottom: 40px;

    @media screen and (max-width: 767px) {
      flex-direction: column;
    }

    &-icon {
      display: block;
      min-width: 34px;
      height: 34px;
      margin: 0 20px;
      background: url("/img/hint-icon.png") no-repeat;
      background-size: contain;

      @media screen and (max-width: 767px) {
        margin-bottom: 10px;
        margin-left: 0;
      }
    }

    &-right {
      display: flex;
      flex-direction: column;
    }

    &-text {
      color: #000;
      font-size: 18px;

      &:first-of-type {
        margin-bottom: 10px;
      }
    }
  }

  .steps {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    counter-reset: step;
    padding: 0;

    &-item {
      position: relative;
      background-color: #fff;
      color: #585858;
      font-size: 14px;
      cursor: auto;
      max-width: 186px;
      white-space: inherit;
      border: 0;
      list-style: none;
      width: 100%;
      text-align: center;
      padding: 10px;
      user-select: none;
      pointer-events: none;

      &:before {
        counter-increment: step;
        content: "Шаг " counter(step);
        display: block;
        font-size: 18px;
        margin-bottom: 4px;
        color: #409DD9;
      }

      &:last-child {

        &:before {
          content: "Результат";
        }
      }

    }

    .active-step {
      color: #fff;
      cursor: pointer;
      opacity: 0.6;
      pointer-events: auto;

      &:before {
        color: #fff;
      }

      &:nth-child(1) {
        background-color: #DE4A67;

        &:after {
          border-top-color: #DE4A67;
        }
      }

      &:nth-child(2) {
        background-color: #C15F82;

        &:after {
          border-top-color: #C15F82;
        }
      }

      &:nth-child(3) {
        background-color: #AC6D95;

        &:after {
          border-top-color: #AC6D95;
        }
      }

      &:nth-child(4) {
        background-color: #9080B0;

        &:after {
          border-top-color: #9080B0;
        }
      }

      &:nth-child(5) {
        background-color: #7791C7;

        &:after {
          border-top-color: #7791C7;
        }
      }
    }

    .current-step {
      opacity: 1;
      color: #fff;
      pointer-events: none;

      &:before {
        color: #fff;
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        bottom: -110px;
        left: 0;
        width: 100%;
        border: 90px solid transparent;
        border-top: 20px solid;
      }
    }
  }

  .step-info {
    display: flex;
    flex-wrap: wrap;
    font-weight: 700;

    &-list {
      margin-bottom: 20px;
      flex-grow: 1;

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        position: relative;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        cursor: initial;
        text-decoration: none;

        &:first-child {
          margin-top: 16px;
        }
      }

      &-title {
        display: block;
        padding-left: 45px;
        margin-bottom: 10px;
        font-size: 18px;
        color: #409DD9;
      }
    }

    &-5 {
      ul {
        counter-reset: num;
        padding-left: 15px;

        li {
          position: relative;

          &:before {
            counter-increment: num;
            content: counter(num) ".";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            font-size: 16px;
            margin-bottom: 5px;
            color: #409DD9;
          }
        }
      }

      .step-info-sidebar {
        .final-text {
          color: #409DD9;
          font-size: 18px;
          margin-bottom: 1rem;
        }

        .btn {
          position: relative;
          padding-left: 60px;
          white-space: inherit;

          &:before {
            content: "";
            position: absolute;
            background: url("/img/details-document.png") no-repeat;
            background-size: contain;
            left: 20px;
            top: 50%;
            transform: translateY(-50%);
            width: 24px;
            height: 24px;
          }
        }
      }
    }

    .image-holder {
      max-width: 200px;
      padding: 2rem;

      img {
        max-width: 100%;
        height: auto;
      }
    }

    ul {
      li {
        p {
          padding-left: 20px;
        }

        a {
          display: block;
          position: relative;
          padding: 10px 0 10px 45px;
          color: #474747;
          font-size: 16px;
          font-weight: 400;
          cursor: pointer;

          &:hover {
            color: #fff;
            background: linear-gradient(to right, #de4a67 1%, #7791c7 100%);

            &:before {
              content: "\e905";
              position: absolute;
              left: 12px;
              top: 50%;
              transform: translateY(-50%);
              color: #fff;
              font-family: icomoon!important;
              speak: none;
              font-style: normal;
              font-weight: 400;
              font-variant: normal;
              text-transform: none;
              line-height: 1;
              -webkit-font-smoothing: antialiased;
            }
          }
        }
      }
    }

    .step-info-sidebar {
      width: 100%;
    }
  }
}

@media screen and (max-width: 1199px) {
  .select-doc {
    .steps {
      &-item {
        span {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .select-doc {
    .steps {
      flex-wrap: wrap;
      border-bottom: 0;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      &-item {
        flex-basis: calc(100% - 20px);
        white-space: normal;
        margin-bottom: 30px;

        span {
          display: inline-block;
        }
      }
    }

    .step-info {
      .image-holder {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .select-doc {
    .steps {
      &-item {
        font-size: 16px;
      }
    }
  }
}
