.news {
  &_content {
    margin-top: 2rem;
  }
  &_latest-list {
    li {
      display: flex;
      gap: 1rem;
      align-items: center;
      margin-top: 1rem;
      span {
        font-size: 1.3rem;
        font-weight: 500;
      }
      a {
        font-size: 1.3rem;
        &:hover {
          color: #097db7;
        }
        >h5 {
          margin: 0!important;
        }
      }
    }
  }
}
