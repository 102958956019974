.sidebar-upload-order {
  max-width: 300px;
  margin: 30px auto 20px;

  a {
    position: relative;
    display: inline-block;
    background-color: #ef3e58e6;
    color: #fff;
    padding: 14px 64px 14px 18px;
    width: 100%;
    text-align: left;
    text-transform: uppercase;

    img {
      position: absolute;
      top: -10px;
      right: 10px;
      max-width: 64px;
    }
  }
}