.btn-to-top {
  position: fixed;
  z-index: 100;
  display: none;
  bottom: 70px;
  right: 20px;
  font-size: 30px;
  cursor: pointer;

  &.show {
    display: block;
  }

  span {
    background-color: #fafafa;
    border: 1px solid #ee3f58;
    border-radius: 50%;
    display: inline-block;
    color: #1293d4;
    font-size: 16px;
    height: 50px;
    line-height: 50px;
    position: relative;
    text-align: center;
    transition: all .3s ease 0s;
    width: 50px;
    transform: rotate(180deg);
  }

  &:hover span {
    background-color: #ee3f58;
    color: #fff;
    border-color: transparent;
  }
}
