.article {
  &_container {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: flex-start;


    @media (max-width: 992px) {
      flex-wrap: wrap;
    }
  }

  &_content {
    width: calc(75% - 20px);
    min-width: 680px;
    margin-right: 20px;
    background-color: #fff;
    padding: 15px;

    @media (max-width: 992px) {
      width: 100%;
      min-width: inherit;
      margin-right: 0;
    }

    &_right {
      margin-right: auto;
      background-color: transparent;
    }

    .post-img{
      min-height: 70px;
    }
  }

  &_sidebar {
    width: 25%;
    min-width: 280px;
    position: sticky;
    top: 124px;

    @media (max-width: 992px) {
      width: 100%;
      min-width: inherit;
      position: relative;
      top: 0;
    }
  }

  &_author {
    display: flex;
    margin: 10px 0 25px;
    justify-content: center;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }

    .author {
      &_info {
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-start;
        min-width: 250px;
        position: relative;

        @media (max-width: 768px) {
          min-width: inherit;
          margin-bottom: 15px;
          margin-right: auto;
        }

        &:after {
          content: '';
          width: 1px;
          height: 80%;
          background-color: #49546C;
          position: absolute;
          right: 0;
          top: 10%;

          @media (max-width: 768px) {
            display: none;
          }
        }
      }

      &_img {
        width: 40px;
        min-width: 40px;
        height: 40px;
        min-height: 40px;
        border-radius: 50%;
        margin-right: 15px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }

      &_content {
        display: flex;
        flex-direction: column;
        margin-right: 20px;
      }

      &_name {
        font-weight: 500;
        font-size: 14px;
        color: #409DD9;
      }
    }
  }

  &_publication {
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
      width: 100%;
      justify-content: center;
      flex-direction: column;
      align-items: flex-start;
    }

    p {
      margin-bottom: 0;
      margin-right: 7px;
      font-size: 14px !important;
      text-indent: 0 !important;
      margin-left: 20px;

      @media (max-width: 768px) {
        margin: 0 !important;
      }
    }
  }
}

.item-article {
  padding: 20px;
  background-color: #fff;
  font-size: 16px;
  color: #49546c;
  margin-bottom: 20px;

  .top-nblock {
    margin-bottom: 10px;
  }

  p {
    text-indent: 30px;
    font-size: 16px;
    color: #49546c;
  }

  ul {
    padding-left: 30px;

    li {
      padding-left: 25px;
      position: relative;
      margin-bottom: 15px;
    }
  }

  ol {
    list-style-type: none;
    counter-reset: num;
    padding-left: 0;

    li {
      margin-bottom: 20px;
      text-indent: 30px;

      &::before {
        content: counter(num) '. ';
        counter-increment: num;
        color: #4f86fe;
      }
    }

    ul {
      margin-top: 10px;

      li{
        &::before {
          content: "";
          counter-increment: b;
        }
      }
    }
  }

  .description-news {
    word-break: normal;

    table {
      margin: 12px auto !important;
      display: table !important;
    }

    .article-image {
      width: 300px;
      float: right;
      margin-left: 10px;

      @media (max-width: 1199px) {
        display: block;
        float: unset;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

.main-page .article {
  &_content {
    width: 100%;
    margin: 50px 0 70px 0;
    background-color: inherit;
  }
}

.content-post {
  p {
    color: #49546c;
    font-weight: 500;
  }

  li::before {
    display: none !important;
  }

  li {
    list-style-type: disc;
    padding-left: 15px !important;

    a {
      font-size: 16px !important;
    }
  }

  ul {
    margin-left: 30px;
  }
}