.header {
  position: sticky;
  top: 0;
  z-index: 20;

  &_container {
    display: flex;
    align-items: center;
    justify-content: center;

    @media(max-width: 1200px) {
      justify-content: space-between;
    }

    .logo{
      width: 214px;
      height: 39px;
    }
  }

  &-top {
    padding: 15px 0 30px 0;
    background: #fff;
    color: #424242;

    &:after {
      content: "";
      height: 10px;
      background: linear-gradient(to right, rgba(222, 74, 103, 1) 1%, rgba(119, 145, 199, 1) 100%);
      display: inline-block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  &-search {
    margin-bottom: 15px;
  }

  .header-menu.mean-container{
    display: block;
  }

  @media screen and (max-width: 1200px) {
    .header-menu{
      display: none;
    }
  }
}

.parent-angle {
  font-size: 12px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#mainMenu {
  .nav {

    & > li > a {
      padding: 0;
      padding-right: 16px;
      position: relative;
      display: block;
    }
  }

  .dropdown-menu {
    margin: 0;
    border-radius: 0 0 4px 4px;
    box-shadow: none;
    min-width: inherit;
    padding: 0;
    z-index: 9999999;
    position: absolute;
    top: 100%;
    left: 0;
    display: none;
    float: left;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .15);

    li {
      min-height: 45px;
      max-height: 45px;
      padding: 0;

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
        cursor: pointer;
      }

      & > a {
        display: block;
        padding: 10px 20px;
        clear: both;
        font-weight: 400;
        line-height: 1.42857143;
        color: #333;
        white-space: nowrap;
      }
    }

    a {
      font-size: 14px !important;
      display: flex !important;
      align-items: center;
      color: #333;
      padding: 10px 15px;

      .img-wrap {
        width: 20px;
        max-width: 20px;
        display: inline-block;
        margin-right: 10px;

        img {
          max-width: 100%;
          height: auto;
        }
      }
    }
  }

  li {
    text-align: left;
    padding: 10px 15px;

    &.dropdown {
      &:hover {
        .dropdown-menu {
          display: block;
        }
      }

      .caret {
        display: none;
      }
    }
  }
}

.nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;

  & > li,
  & > li > a {
    position: relative;
    display: block;
  }

  &__list {
    display: -ms-flexbox;
    display: flex;
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
    padding: 0 30px;
    margin: 0;
    list-style-type: none;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;

    a {
      color: #474747;
      display: inline-block !important;
      font-size: 16px !important;
      text-transform: uppercase;
      box-sizing: border-box !important;

      &:focus {
        color: #474747;
        text-decoration: none;
        background-color: transparent !important;
      }

      &:hover {
        color: #669cd6;
        text-decoration: none;
        background-color: transparent !important;
      }
    }

    &.active {
      a {
        color: #669cd6;
        text-decoration: none;
        background-color: transparent !important;
      }
    }
  }
}


@media screen and (max-width: 1200px) {
  nav.mean-nav {
    li.dropdown {
      .parent-angle {
        right: 18px;
      }

      .caret {
        display: none;
      }

      .dropdown-menu {
        position: static;

        a {
          .img-wrap {
            display: none;
          }
        }
      }

      a.mean-expand {
        position: absolute;
        right: 0;
        top: 0;
        opacity: 0;
        width: 50px;
      }
    }
  }
}

.nav__list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
  padding: 0 30px;
  margin: 0;
  list-style-type: none;
}

.nav__list a {
  color: #474747;
  display: inline-block;
  font-size: 16px;
  text-transform: uppercase;
}

.nav__list a:focus {
  color: #474747;
  text-decoration: none;
  background-color: transparent;
}

.nav__list a:hover,
.nav__list .active a {
  color: #669cd6;
  text-decoration: none;
  background-color: transparent;
}
