.sidebar-postlists {
  li {
    margin-bottom: 25px;
  }
}

.article_sidebar {
  .sidebar-banner {
    .banner-layout {
      text-align: center;
      background: #409DD9;
      padding: 30px 20px;
      font-weight: 500;

      .title {
        font-size: 16px;
        text-transform: uppercase;
        color: #FFFFFF;
      }

      .description {
        font-size: 14px;
        color: #FFFFFF;
      }

      .cards {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -4px;


        .card {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          margin: 4px;
          width: 100%;
          max-width: calc(50% - 8px);
          background: #FFFFFF;
          padding: 18px 6px;
          min-height: 114px;

          .card-icon {
            max-width: 50px;
          }

          .card-title {
            color: #409DD9;
            font-size: 13px;
            margin: 0;
          }
        }
      }

      .btn {
        margin-top: 24px;
        min-width: 190px;
      }
    }
  }
}
