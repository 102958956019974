@media (max-width: 1279px) {
    ul.tree > li {
        width: 100%;
    }
}

@media (max-width: 1199px) {
    .direction-inline ul li {
        padding: 0 6px;
    }
    .article-news {
        display: none;
    }

    .list-tabs li {
        width: 50% !important;
    }
}

@media (min-width: 992px) and (max-width: 1169px) {
    h3, .title {
        font-size: 25px;
        font-weight: 700;
        line-height: 22px;
    }
}

/* Tablet desktop :768px. */
@media (min-width: 768px) and (max-width: 991px) {
    .blog-sidebar {
        margin-top: 50px;
    }
}

@media (max-width: 991px) {
    .direction-inline ul li {
        padding: 0;
    }

    .article-news {
        display: none;
    }

    .tel-form .wrapp-form form {
        width: 230px;
        margin: 0 auto;
        text-align: center;
    }

    .list-tabs .item {
        padding-right: 0;
        padding-left: 0;
    }

    .formflex form {
        justify-content: center;
        flex-wrap: wrap;
    }

    .formflex input {
        margin-bottom: 5px;
    }
}

@media (max-width: 767px) {

    .direction-inline ul {
        flex-wrap: wrap;
    }

    ul.tree ul {
        margin-left: 20px;
    }

    .direction-inline ul li {
        width: 100%;
        text-align: left;
    }

    .direction-inline ul li span {
        display: inline-block;
        vertical-align: middle;
        margin-right: -4px;
    }

    .h3okno {
        padding-left: 20px;
        font-size: 18px;
    }

    ul.tree li span {
        margin-right: 5px;
    }

    ul.tree ul {
        margin-left: 10px;
    }

    ul.tree > li {
        padding: 0 5px;
    }

    ul.tree > li a {
        font-size: 14px;
    }

    .head-ar .n-date span {
        margin-top: 9px;
        display: inline-block;
    }

    .info-box {
        margin-bottom: 35px;
    }

    .contents {
        text-align: center;
    }

    h3, .title {
        font-size: 25px;
        font-weight: 700;
        line-height: 35px;
    }

    .blog-sidebar {
        margin-top: 50px;
    }

    .section-title {
        text-align: center;
    }

    .modal-dialog {
        margin: 10px auto;
    }

    .tab-content {
        padding: 15px;
    }

    .list-tabs {
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin-left: 0 !important;
    }

    #comment-form .form-group {
        display: block;
        margin: 0 auto;
    }

    #comment-form .field-commentmodel-recaptcha {
        margin: 0 auto;
    }

    .genpt .title-saminormal {
        font-size: 22px;
    }

    .genpt .title-saminormal span {
        font-size: 34px;
    }

    .genpt ul li {
        font-size: 20px;
    }
}

/* small mobile :320px. */
@media (max-width: 478px) {
    .genpt .title-saminormal {
        font-size: 22px;
    }

    .genpt .title-saminormal span {
        font-size: 34px;
    }

    .descr-news {
        flex-wrap: wrap;
    }

    .genpt ul li {
        font-size: 20px;
    }

    .tab-content {
        padding: 15px;
    }

    .tab-content .ncat {
        display: none;
    }

    .tab-content .descr-news {
        display: block;
        text-align: center;
    }

    .tab-content .ndiscription h5 {
        text-align: center;
    }

    .descr-news .author-rimg {
        width: 50%;
        margin-bottom: 10px;
    }

    .descr-news p {
        width: 100%;
    }

    .tab-content .descr-news p {
        width: 100%;
        padding-left: 0;
        text-align: left;
    }

    .list-tabs {
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin-left: 0 !important;
    }

    .field-commentmodel-recaptcha {
        overflow: hidden;
    }

    .ncat {
        display: none;
    }
}

@media (max-width: 360px) {
    .tel-form .wrapp-form form {
        width: 200px;
    }

    .tel-form .wrapp-form {
        width: 230px;
    }
}
