.section-comment {
  padding: 20px;

  .comment-list {
    .comment-layout {
      .comment_head {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }

      .comment-body {
        color: #fff;
        padding: 15px;
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 20px;
      }
    }

    .question-comment {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 12px;

      .question-comment-layout {
        .comment_head {
          align-items: flex-start;
        }

        .comment-body {
          background-color: rgba($color: #ee3f58, $alpha: 0.7);
          border-top-right-radius: 20px;
        }
      }
    }

    .answer-comment {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 12px;

      .answer-comment-layout {

        .comment-body {
          background-color: rgba($color: #669cd6, $alpha: 0.7);
          border-top-left-radius: 20px;
        }
      }
    }
  }

  .comment-form {
    box-shadow: 0 0 17px 1px rgba(75, 75, 75, .11);
    padding: 30px;
    margin: 20px 0;

    @media screen and (max-width: 768px) {
      padding: 12px;
      margin: 12px;
    }

    .comments-form-title {
      color: #669cd6;
      margin-bottom: 20px;
      text-align: center;
    }

    .form-set {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      .form-group {
        max-width: 48%;
        margin: 0 0 14px;

        @media screen and (max-width: 768px) {
          max-width: 100%;
        }
      }
    }

    .form-set-bottom {
      justify-content: center;

      > div:first-child{
        height: 90px;
      }
    }

    .form-group {
      width: 100%;
      text-align: center;

      input,
      textarea,
      select {
        background-color: #F4F4F4 !important;
        border-color: transparent;
        border-radius: 0;
        box-shadow: none;
        color: #474747;

        &::placeholder {
          color: #474747;
        }
      }

      textarea {
        resize: none;
      }
    }

    .field-commentmodel-recaptcha {
      margin-left: 0;
      transform: scale(0.78);

      @media (max-width: 360px) {
        transform: scale(.67);
      }

      .g-recaptcha>div {
        @media screen and (max-width: 768px) {
          margin: 0 auto;
        }
      }
    }
  }
}