@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Russo+One&display=swap");
:root {
  --typo-secondary-color: #1293D4;
  --typo-main-color: #424242; }

.flex-direction-column {
  flex-direction: column; }

/* Search form */
.search-form input, .search-form button {
  height: 45px;
  border-radius: 0; }

.sidebar-widget {
  margin-bottom: 30px; }

.blog-sidebar .sidebar-widget:last-child {
  margin-bottom: 0; }

.sidebear-post-thumb {
  width: 100%;
  text-align: left;
  max-height: 150px;
  min-height: 70px;
  margin-bottom: 20px;
  overflow: hidden; }

.categor-p .list-view > div .sidebear-post-thumb {
  max-width: 250px;
  position: relative;
  height: 150px;
  margin-right: 16px;
  width: 100%;
  text-align: left;
  max-height: 150px;
  margin-bottom: 20px;
  overflow: hidden;
  justify-content: center;
  flex-direction: column; }

.article_content.categor-p .list-view > div .sidebear-post-thumb {
  height: 157px;
  max-height: 157px; }

.categor-p .list-view .article_author {
  justify-content: flex-start; }
  .categor-p .list-view .article_author .author_content {
    margin-right: 10px; }
  .categor-p .list-view .article_author .author_info {
    min-width: auto; }
  .categor-p .list-view .article_author .article_publication > p {
    margin-left: 10px; }
  .categor-p .list-view .article_author .link-readmore {
    position: relative;
    margin-left: auto;
    align-self: flex-end; }
    @media only screen and (max-width: 768px) {
      .categor-p .list-view .article_author .link-readmore {
        margin: 15px auto 0 5px; } }

.categor-p .list-view > div .sidebar-postcontent {
  width: 100%; }

.categor-p .list-view > div .title-exparticle {
  text-align: left; }

.categor-p .list-view > div .title-exparticle a {
  font-size: 18px; }

.categor-p .list-view > div .sidebear-post-thumb a,
.categor-p .list-view > div .sidebear-post-thumb img {
  width: 100%;
  height: 100%;
  display: block;
  -o-object-fit: cover;
  object-fit: cover; }

.sidebar-widget .title-saminormal a {
  text-transform: capitalize; }

.tab-content {
  padding: 20px 40px;
  background-color: #f4f4f4; }

.tab-style3 .tab-content {
  background-color: transparent;
  padding: 10px 0; }

/*---------- accordion -------------*/
.panel > .panel-heading {
  display: block; }

.form-messege.error {
  border: 2px solid #d35400;
  color: #d35400;
  margin-top: 10px;
  padding: 4px; }

.form-messege.success {
  border: 2px solid #0b9444;
  color: #0b9444;
  margin-top: 10px;
  padding: 4px; }

.li-without-marker {
  list-style-type: none;
  margin-top: 10px; }

.cat-text {
  text-align: justify; }

#comment-form .field-commentmodel-username input {
  padding: 5px 10px; }

#comment-form textarea {
  width: 100%;
  max-width: 100%;
  background: #fff;
  border: 1px solid #dcd9d9; }

.main-header-wrap {
  display: flex;
  align-items: center; }

a {
  text-transform: none; }

.sidebar-widget .title-saminormal a {
  text-transform: none; }

.pagination .page-list li a,
.pagination .page-list li span {
  border-radius: 3px; }

.search-form input,
.search-form button {
  height: 37px; }

#main-block {
  min-height: 80%;
  min-width: 320px; }

#articles-page .list-inline > li > div {
  padding: 10px; }

.pointer {
  cursor: pointer; }

header {
  position: relative; }

.sidebar-postlists .sidebar-postcontent .title-saminormal {
  margin-bottom: 5px; }

.sidebar-postlists .sidebar-postcontent .anno {
  margin-top: 5px; }

#news-page .news-list > li {
  padding: 10px;
  width: 100% !important;
  display: flex;
  position: relative;
  background: #ffffff; }
  @media screen and (max-width: 576px) {
    #news-page .news-list > li {
      flex-direction: column;
      align-items: center; } }
  #news-page .news-list > li .head-ar {
    position: absolute;
    right: 10px;
    bottom: 10px; }
  #news-page .news-list > li .sidebear-post-thumb {
    max-width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: 10px; }
    #news-page .news-list > li .sidebear-post-thumb img {
      max-width: 100%;
      height: auto; }
  #news-page .news-list > li .sidebar-postcontent .title-exparticle {
    text-align: left; }
  #news-page .news-list > li .sidebar-postcontent .text-primary {
    padding: 0; }
  #news-page .news-list > li .sidebar-postcontent .link-readmore {
    left: 0; }

.news-section {
  border-radius: 4px;
  padding-bottom: 10px; }

.news-section .sidebar-postlists li {
  background: #fff;
  border-radius: 4px;
  padding: 10px; }

.news-list .item {
  background: #eee;
  height: 100%; }

ul.news-list {
  flex-direction: column; }

#ui-datepicker-div {
  z-index: 10 !important; }

@media only screen and (max-width: 480px) {
  .tel-form {
    padding: 40px 5px; }
  .col-lg-4.col-xs-12.pt30.tf-article,
  .col-md-12.col-md-6.col-lg-5.col-xs-12 {
    overflow: hidden;
    padding: 0; } }

ul.tree, ul.tree ul {
  list-style-type: none;
  margin: 0;
  padding: 0; }

ul.tree {
  margin-bottom: 20px; }

ul.tree ul {
  margin-left: 40px; }

ul.tree li {
  padding: 5px 12px; }

ul.tree li div span {
  font-size: 14px;
  display: inline-block;
  margin-left: 20px; }

ul.tree > li {
  margin: 0 0 10px;
  padding: 0 12px;
  line-height: 26px;
  font-size: 18px;
  color: #669cd6;
  font-weight: bold; }

ul.tree > li a {
  color: #669cd6 !important;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 26px;
  vertical-align: middle; }

ul.tree li span {
  color: #669cd6 !important;
  font-size: 1.2em;
  vertical-align: middle;
  margin-right: 5px; }

ul.tree li a {
  color: #3288df !important;
  font-weight: 400; }

ul.tree ul.pagination li {
  background: none; }

ul.tree > div:last-child {
  background-color: #fff; }

.top-nblock {
  color: #4b4b56;
  margin-bottom: 8px; }

.ncat {
  color: #4b4b56;
  background: #dddee1;
  padding: 2px 13px 0;
  border-radius: 10px;
  margin-right: 10px;
  margin-bottom: 5px; }

.ndiscription h5 {
  color: #5284ff;
  font-size: 18px;
  font-weight: 700; }

.descr-news {
  color: #8492af;
  display: flex; }

.descr-news a {
  color: #8492af; }

.descr-news .author-rimg {
  display: inline-block;
  width: 25%;
  padding: 0 10px; }

.descr-news .author-rimg img {
  border-radius: 50%;
  width: 100%; }

.descr-news p {
  width: 75%;
  padding-left: 10px; }

.mh-text p {
  font-weight: 700;
  color: #99aad1;
  font-size: 18px; }

.mh-text h3 {
  color: #3e6aca;
  font-size: 30px;
  font-weight: 700; }

.btn-closex {
  position: absolute;
  top: 21px;
  right: 21px;
  width: 25px;
  height: 25px;
  opacity: 1 !important; }

.btn-closex:hover {
  opacity: 0.7 !important; }

.btn-closex span {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative; }

.btn-closex span:before,
.btn-closex span:after {
  content: "";
  width: 25px;
  height: 4px;
  background: #c7c7c7;
  display: block;
  border-radius: 1px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateX(-50%) translateY(-50%); }

.btn-closex span:before {
  transform: rotate(45deg); }

.btn-closex span:after {
  transform: rotate(-45deg); }

.rc-anchor-normal {
  width: 100% !important; }

.list-height {
  display: flex;
  flex-wrap: wrap; }

.list-height li {
  background-clip: content-box;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-bottom: 0; }

.list-height li article {
  padding: 10px; }

.content-wrapper {
  overflow: hidden; }

.article__main-col {
  background-color: #fff; }

.content-wrapper .h3 {
  color: #000;
  font-size: 35px;
  font-weight: 400;
  margin-bottom: 15px; }

.has-error input {
  background: transparent !important; }

.title-exparticle {
  color: #000;
  font-size: 20px;
  text-align: center;
  margin-bottom: 15px; }

.sidebar-postcontent img {
  width: 100%;
  height: auto; }

.tree span.counter {
  display: inline-block !important;
  vertical-align: top !important;
  background: #3e6aca !important;
  color: #fff !important;
  border-radius: 10px !important;
  padding: 2px 3px 1px !important;
  font-size: 9px !important;
  margin-top: 3px !important;
  margin-left: 3px !important;
  line-height: 10px; }

.form-group input,
.form-group select {
  border-radius: 3px !important; }

.btn-pull-left {
  float: left; }

.list-tabs .item {
  margin-bottom: 30px; }

.tab-content .list-height {
  background: transparent; }

.tel-form {
  background: rgba(239, 62, 88, 0.9);
  padding: 40px 15px;
  margin-bottom: 20px; }
  @media (max-width: 991px) {
    .tel-form {
      margin-top: 20px; } }
  @media (max-width: 767px) {
    .tel-form {
      margin-right: 15px;
      margin-left: 15px; } }

.tel-form p {
  font-size: 16px;
  color: #fff;
  padding-left: 5px;
  padding-right: 5px;
  text-transform: uppercase;
  margin-bottom: 20px; }

.form-titletext {
  text-align: center; }

.tel-form select option {
  background: rgba(239, 62, 88, 0.9); }

#getdetailsconsultationform-comment {
  min-height: 120px; }

.tel-form label {
  color: #fff;
  font-size: 16px;
  position: relative; }

.tel-form input[type=checkbox]:before {
  content: "";
  display: inline-block;
  border: 1px solid #fff;
  border-radius: 2px;
  width: 15px;
  height: 15px;
  background-color: #f05068;
  position: absolute;
  left: 0;
  right: 0;
  top: -5px;
  bottom: 0; }
  @media (max-width: 767px) {
    .tel-form input[type=checkbox]:before {
      display: none; } }

.tel-form input[type=checkbox]:checked:after {
  content: "\2714";
  display: inline-block;
  vertical-align: top;
  font-size: 9px;
  font-weight: bold;
  text-align: center;
  border: 1px solid #fff;
  border-radius: 2px;
  color: #fff;
  width: 15px;
  height: 15px;
  background-color: #f05068;
  position: absolute;
  left: 0;
  right: 0;
  top: -5px;
  bottom: 0;
  z-index: 2; }
  @media (max-width: 767px) {
    .tel-form input[type=checkbox]:checked:after {
      display: none; } }

.tel-form #getdetailsconsultationform-agree,
.tel-form #getexpertconsultationform-agree {
  top: -4px; }
  @media (max-width: 767px) {
    .tel-form #getdetailsconsultationform-agree,
    .tel-form #getexpertconsultationform-agree {
      top: -6px; } }

.tel-form .field-getdetailsconsultationform-agree label,
.tel-form .field-getexpertconsultationform-agree label {
  font-size: 9px;
  top: 3px; }

.forms-text {
  font-weight: 700;
  text-transform: none !important; }

.smt-form {
  color: #ffc5ce !important;
  font-size: 16px;
  line-height: 1.2em;
  text-transform: none !important; }

.box-info .control-label {
  color: #747474;
  font-size: 14px;
  display: block;
  text-align: left; }

.box-info .form-control {
  margin-bottom: 24px;
  border: 1px solid #dcdcdc;
  border-radius: 3px; }

.h3okno {
  font-size: 20px;
  color: #000;
  padding-left: 30px;
  position: relative;
  font-weight: 400;
  margin-bottom: 20px;
  text-transform: uppercase; }

.h3okno:before {
  content: "";
  width: 4px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background: #669cd6; }

.head-ar {
  margin-bottom: 10px; }

.head-ar .title {
  color: #669cd6;
  font-size: 16px;
  text-decoration: underline; }

.article-twr {
  padding-bottom: 28px;
  position: relative; }

.article-twr p.text-primary {
  padding: 10px 45px 10px 15px;
  position: relative;
  background: #fff;
  overflow: hidden; }
  @media screen and (max-width: 767px) {
    .article-twr p.text-primary {
      padding: 0; } }

.article-twr p.text-primary a {
  color: #747373;
  font-size: 14px; }

.article-twr p.text-primary:before {
  content: "";
  position: absolute;
  bottom: -20px;
  right: 20%;
  border-top: 20px solid #f4f4f4;
  border-right: 0 solid transparent;
  border-left: 24px solid transparent; }

.link-readmore {
  position: absolute;
  bottom: 5px;
  left: 5px; }

.link-readmore a {
  color: #e7445e; }

.link-readmore a:hover {
  opacity: 0.8; }

.bottom-section select,
.form-group select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 7% !important;
  background: url("/img/arr-down.png") no-repeat 97% 50% !important; }

.form-section .help-block {
  color: #fff !important; }

.direction li {
  margin-bottom: 20px; }

.direction li a:hover {
  text-decoration: none; }

.direction-inline {
  margin-bottom: 30px; }

.direction-inline ul {
  text-align: center; }

.direction-inline ul li {
  text-align: center;
  width: 20%;
  padding: 0 30px;
  display: inline-block;
  vertical-align: top;
  margin-right: -4px; }

.formflex form {
  display: flex;
  justify-content: flex-end; }

.formflex form button {
  height: 32px; }

div.empty {
  margin-top: 10px !important;
  margin-left: 5px !important; }

.categor-p {
  background-color: #fff;
  padding-left: 35px;
  padding-right: 30px; }

.categor-p .list-view > div {
  margin-right: -4px;
  width: 100%;
  display: flex;
  padding-top: 42px; }
  @media screen and (max-width: 767px) {
    .categor-p .list-view > div {
      flex-direction: column; } }

.categor-p .list-view > div.pagination {
  flex-direction: row; }

ul.tree li span.js-expanded {
  color: #f71336 !important; }

ul.tree span.js-expanded + a.codes-tree {
  color: #f71336 !important; }

.tree-node {
  background-color: #f4f4f4;
  overflow: hidden; }

ul.tree-node > li a,
ul.tree-node li span {
  color: #669cd6 !important; }

#js-search-main-scope {
  display: inline-block; }

ul {
  margin: inherit;
  padding: inherit; }

li {
  list-style-type: inherit; }

.faq h3 {
  margin: 20px 0; }

.banner-text-red {
  color: #f15169;
  font-size: 24px;
  font-weight: bold; }

@media screen and (max-width: 767px) {
  .expert-subscription-form .row {
    margin: 0; } }

.tf-article .tel-form {
  background: #ef3e58; }

.select2-selection__clear {
  font-size: 14px !important;
  cursor: pointer; }

.select2-search__field {
  width: 100% !important; }

.faq p {
  text-align: justify; }

.faq .docs {
  text-align: center; }
  @media screen and (min-width: 768px) {
    .faq .docs {
      display: flex;
      justify-content: space-between; } }
  .faq .docs img {
    margin-bottom: 25px; }
    @media screen and (min-width: 768px) {
      .faq .docs img {
        max-width: 30%; } }

.categories__list {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  margin-left: -20px;
  margin-right: -20px; }

.category-item {
  margin-bottom: 60px;
  width: 50%;
  padding-left: 30px;
  padding-right: 30px; }
  @media (max-width: 767px) {
    .category-item {
      width: 100%;
      padding: 0; } }
  .category-item_expand {
    width: 80%;
    left: 50%;
    position: relative;
    transform: translateX(-50%); }
    @media (max-width: 767px) {
      .category-item_expand {
        width: 100%; } }
  .category-item__inner {
    display: flex;
    align-items: center;
    background-color: #f4f4f4; }
  .category-item__img-box {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid #409dd9;
    flex-shrink: 0;
    background-color: #fff; }
    .category-item__img-box img {
      max-width: 60px;
      height: auto; }
  .category-item__label {
    color: #333;
    font-size: 16px;
    line-height: 24px;
    margin-left: 10px;
    margin-right: 10px;
    letter-spacing: -0.5px;
    margin-bottom: 0;
    font-weight: 500;
    padding-top: 15px;
    padding-bottom: 15px; }
    @media (max-width: 767px) {
      .category-item__label {
        font-weight: 400; } }
    .category-item__label_expand {
      color: #f15168;
      font-size: 16px;
      line-height: 24px;
      text-transform: uppercase;
      display: flex;
      align-items: center; }
      @media (max-width: 767px) {
        .category-item__label_expand {
          display: block;
          text-align: center; } }

[data-collapse-button] {
  cursor: pointer;
  margin-top: 30px; }

ul:not([class]) {
  list-style: none;
  margin-left: 0;
  padding-left: 0; }
  ul:not([class]) li {
    padding-left: 1em;
    text-indent: -1em; }
  ul:not([class]) li:before {
    content: "";
    display: inline-block;
    background: url("/img/ul-dot.png") center/contain no-repeat;
    width: 14px;
    height: 14px;
    margin-right: 5px; }

.chosen-categories-block {
  border: 3px solid #669cd6;
  padding: 5px;
  margin-top: 30px; }
  @media (max-width: 1199px) {
    .chosen-categories-block {
      margin-left: 20px;
      margin-right: 20px; } }

.chosen-categories__title {
  color: #ff3d58;
  letter-spacing: -0.3px;
  text-transform: uppercase;
  font-size: 15px; }

.chosen-categories__list {
  list-style-type: none; }

.chosen-categories-list__item {
  margin-bottom: 5px;
  cursor: pointer; }

.check-button-disabled [type=submit] {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  box-shadow: none;
  opacity: .65;
  pointer-events: none; }
  .check-button-disabled [type=submit]:hover {
    color: inherit; }

.mobile-expert-widget {
  display: none; }
  @media (max-width: 767px) {
    .mobile-expert-widget {
      display: block; } }

@media (max-width: 767px) {
  .certification .tel-form {
    display: none; } }

#articles-page .sidebar-postlists > li {
  background-color: #fff; }

.select2-container--krajee .select2-selection {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #555555;
  font-size: 14px;
  outline: 0; }

.select2-container--krajee .select2-selection {
  background: 0 0 !important; }

.sitemap-block {
  margin-top: 20px; }
  .sitemap-block:last-child {
    margin-bottom: 25px; }
  .sitemap-block ul, .sitemap-block li {
    margin: 20px 0; }
  .sitemap-block a {
    line-height: 2; }

.tel-form .personal-data-processing {
  margin-top: 22px; }

.tel-form .personal-data-processing .personal-data-processing--text {
  font-size: 11px;
  color: white; }

.tel-form .personal-data-processing .personal-data-processing--link {
  font-size: 11px;
  text-decoration: underline;
  color: white; }

#team-page {
  padding-top: 20px; }
  #team-page .pagination {
    margin-bottom: 9px; }
  #team-page .content-wrapper {
    background: #fff;
    padding: 0 20px; }
    @media screen and (max-width: 767px) {
      #team-page .content-wrapper {
        padding: 0 10px; } }
  #team-page .team-list {
    background: #fff;
    display: flex;
    justify-content: space-between; }

#events-page {
  padding-top: 20px; }
  #events-page .content-wrapper {
    background: #fff;
    padding: 0 20px; }
    @media screen and (max-width: 767px) {
      #events-page .content-wrapper {
        padding: 0 10px; } }
  #events-page .events-list {
    padding: 20px 0; }
    @media screen and (max-width: 767px) {
      #events-page .events-list {
        padding: 10px 0 20px 0; } }

#advantages {
  margin: 50px 0; }
  #advantages .content-wrapper {
    background: #fff;
    padding: 17px 5px; }
    #advantages .content-wrapper .h2okno {
      font-style: normal;
      font-weight: 500;
      font-size: 35px;
      line-height: 41px;
      text-align: center;
      margin-bottom: 50px;
      margin-top: 20px;
      color: #424242; }
      @media screen and (max-width: 767px) {
        #advantages .content-wrapper .h2okno {
          font-size: 28px;
          line-height: 33px; } }
    #advantages .content-wrapper .advantages-grid {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
    @media screen and (max-width: 767px) {
      #advantages .content-wrapper {
        padding: 0 10px; } }

@media (max-width: 1279px) {
  ul.tree > li {
    width: 100%; } }

@media (max-width: 1199px) {
  .direction-inline ul li {
    padding: 0 6px; }
  .article-news {
    display: none; }
  .list-tabs li {
    width: 50% !important; } }

@media (min-width: 992px) and (max-width: 1169px) {
  h3, .title {
    font-size: 25px;
    font-weight: 700;
    line-height: 22px; } }

/* Tablet desktop :768px. */
@media (min-width: 768px) and (max-width: 991px) {
  .blog-sidebar {
    margin-top: 50px; } }

@media (max-width: 991px) {
  .direction-inline ul li {
    padding: 0; }
  .article-news {
    display: none; }
  .tel-form .wrapp-form form {
    width: 230px;
    margin: 0 auto;
    text-align: center; }
  .list-tabs .item {
    padding-right: 0;
    padding-left: 0; }
  .formflex form {
    justify-content: center;
    flex-wrap: wrap; }
  .formflex input {
    margin-bottom: 5px; } }

@media (max-width: 767px) {
  .direction-inline ul {
    flex-wrap: wrap; }
  ul.tree ul {
    margin-left: 20px; }
  .direction-inline ul li {
    width: 100%;
    text-align: left; }
  .direction-inline ul li span {
    display: inline-block;
    vertical-align: middle;
    margin-right: -4px; }
  .h3okno {
    padding-left: 20px;
    font-size: 18px; }
  ul.tree li span {
    margin-right: 5px; }
  ul.tree ul {
    margin-left: 10px; }
  ul.tree > li {
    padding: 0 5px; }
  ul.tree > li a {
    font-size: 14px; }
  .head-ar .n-date span {
    margin-top: 9px;
    display: inline-block; }
  .info-box {
    margin-bottom: 35px; }
  .contents {
    text-align: center; }
  h3, .title {
    font-size: 25px;
    font-weight: 700;
    line-height: 35px; }
  .blog-sidebar {
    margin-top: 50px; }
  .section-title {
    text-align: center; }
  .modal-dialog {
    margin: 10px auto; }
  .tab-content {
    padding: 15px; }
  .list-tabs {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: 0 !important; }
  #comment-form .form-group {
    display: block;
    margin: 0 auto; }
  #comment-form .field-commentmodel-recaptcha {
    margin: 0 auto; }
  .genpt .title-saminormal {
    font-size: 22px; }
  .genpt .title-saminormal span {
    font-size: 34px; }
  .genpt ul li {
    font-size: 20px; } }

/* small mobile :320px. */
@media (max-width: 478px) {
  .genpt .title-saminormal {
    font-size: 22px; }
  .genpt .title-saminormal span {
    font-size: 34px; }
  .descr-news {
    flex-wrap: wrap; }
  .genpt ul li {
    font-size: 20px; }
  .tab-content {
    padding: 15px; }
  .tab-content .ncat {
    display: none; }
  .tab-content .descr-news {
    display: block;
    text-align: center; }
  .tab-content .ndiscription h5 {
    text-align: center; }
  .descr-news .author-rimg {
    width: 50%;
    margin-bottom: 10px; }
  .descr-news p {
    width: 100%; }
  .tab-content .descr-news p {
    width: 100%;
    padding-left: 0;
    text-align: left; }
  .list-tabs {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: 0 !important; }
  .field-commentmodel-recaptcha {
    overflow: hidden; }
  .ncat {
    display: none; } }

@media (max-width: 360px) {
  .tel-form .wrapp-form form {
    width: 200px; }
  .tel-form .wrapp-form {
    width: 230px; } }

@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon/fonts/icomoon.eot?13pb8w");
  src: url("../fonts/icomoon/fonts/icomoon.eot?13pb8w#iefix") format("embedded-opentype"), url("../fonts/icomoon/fonts/icomoon.ttf?13pb8w") format("truetype"), url("../fonts/icomoon/fonts/icomoon.woff?13pb8w") format("woff"), url("../fonts/icomoon/fonts/icomoon.svg?13pb8w#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-arrow-long:before {
  content: ""; }

.icon-arrow-left:before {
  content: ""; }

.icon-arrow-right:before {
  content: ""; }

.icon-email:before {
  content: ""; }

.icon-arrow-down:before {
  content: ""; }

.icon-search:before {
  content: ""; }

.m-0 {
  margin: 0; }

.mb-20 {
  margin-bottom: 20px; }

.mt-10 {
  margin-top: 10px; }

.mb5 {
  margin-bottom: 5px !important; }

.relative {
  position: relative; }

.absolute {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 100%; }

.clear {
  clear: both; }

.fix {
  overflow: hidden; }

.text-center {
  text-align: center; }

.pull-left {
  float: left; }

.pull-right {
  float: right; }

.color-red {
  color: #ef3e58 !important; }

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 768px) {
    .container {
      width: 750px; } }
  @media (min-width: 992px) {
    .container {
      width: 970px; } }
  @media (min-width: 1200px) {
    .container {
      width: 1170px; } }

html, body {
  height: 100%;
  font-family: 'Roboto', sans-serif;
  background-color: #f4f4f4;
  margin: 0; }

body {
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  color: #424242;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch; }

*, :after, :before {
  box-sizing: border-box; }

h1, h2, h3, h4, h5, h6 {
  margin: 0 0 10px;
  font-weight: 700;
  text-transform: none; }

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  color: inherit;
  font-weight: inherit; }
  h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover {
    text-decoration: none; }

h1 a:hover {
  color: #1293d4; }

.h2, h2 {
  font-size: 28px; }
  @media (max-width: 767px) {
    .h2, h2 {
      font-size: 23px; } }

.h3, h3 {
  font-size: 24px; }

p {
  margin: 0 0 15px;
  color: #424242;
  font-size: 16px;
  line-height: 1.2em; }

hr {
  margin: 20px 0;
  padding: 0;
  border-bottom: 1px solid #eceff8;
  border-top: 0; }

em, i {
  font-style: italic; }

b, strong {
  font-weight: 700; }

big, .big {
  font-size: 110%;
  line-height: 180%; }

dt {
  font-weight: 700; }

span {
  font-weight: 400;
  font-size: 14px; }

ul {
  margin: 0;
  padding: 0; }

li {
  list-style: none; }

a {
  transition: all 0.3s ease 0s;
  text-decoration: none;
  color: #1293d4;
  font-size: 14px; }

a:hover {
  text-decoration: none;
  color: #1293d4; }

a:active,
a:hover {
  outline: 0 none; }

a,
.btn {
  transition: all 0.3s ease-out 0s; }

a:focus,
.btn:focus {
  text-decoration: none;
  outline: none; }

a,
button,
input {
  outline: medium none;
  color: #1293d4; }

.title {
  font-weight: 700;
  font-size: 30px;
  line-height: 22px;
  color: #333333; }

h3 a:hover,
.title a:hover {
  color: #1293d4; }

h4 a:hover {
  color: #1293d4; }

.mark, mark {
  background: #4fc1f0;
  color: #ffffff; }

.btn {
  display: inline-block;
  text-align: center;
  border: none;
  outline: 0;
  border-radius: 20px;
  text-transform: uppercase;
  color: #fff;
  padding: 10px 14px;
  margin: 0;
  background-color: #ee3f58; }
  .btn:hover, .btn:focus {
    color: #fff; }

.btn-white {
  background: #fff;
  border-radius: 20px;
  color: #ef3e58;
  border: 1px solid #fff;
  text-transform: uppercase;
  white-space: nowrap; }
  .btn-white:hover {
    background: transparent;
    color: #fff; }

.btn-blue {
  background-color: #669cd6; }
  .btn-blue:active, .btn-blue:focus, .btn-blue:hover {
    color: #669cd6;
    background-color: #fff; }

iframe {
  max-width: 100%; }

button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer; }

img, .img {
  max-width: 100%;
  height: auto; }

.h1okno {
  font-size: 35px;
  color: #000;
  padding-left: 30px;
  position: relative;
  font-weight: 400;
  margin-bottom: 30px;
  margin-top: 20px; }
  @media (max-width: 767px) {
    .h1okno {
      font-size: 25px;
      padding-left: 18px; } }
  .h1okno:before {
    content: "";
    width: 4px;
    position: absolute;
    left: 0;
    top: 5px;
    bottom: 5px;
    background: #e85f77; }

blockquote {
  padding: 10px 20px;
  margin: 0 0 15px;
  border-left: 5px solid #e85f77; }

body .container blockquote p {
  font-size: 17px; }

.typo-main-color {
  color: var(--typo-main-color); }
  .typo-main-color-parent * {
    color: var(--typo-main-color) !important; }

.typo-secondary-color {
  color: var(--typo-secondary-color); }

.mean-container a.meanmenu-reveal {
  color: #333333;
  cursor: pointer;
  display: inline-block;
  height: 42px;
  line-height: 22px;
  text-decoration: none;
  width: 54px;
  border-radius: 50%;
  position: absolute;
  left: auto !important;
  right: 0 !important;
  top: 0 !important; }
  .mean-container a.meanmenu-reveal:after, .mean-container a.meanmenu-reveal:before,
  .mean-container a.meanmenu-reveal span {
    content: "";
    position: absolute;
    width: 30px;
    height: 4px;
    background: #848484;
    display: inline-block; }
  .mean-container a.meanmenu-reveal:before {
    top: 11px;
    left: 12px; }
  .mean-container a.meanmenu-reveal:after {
    bottom: 11px;
    left: 12px; }
  .mean-container a.meanmenu-reveal span {
    top: 19px;
    left: 12px; }

.mean-container .mean-nav {
  position: absolute;
  left: 0;
  right: 0;
  top: 62px;
  z-index: 8;
  width: 100%;
  overflow: hidden; }

.mean-container .mean-nav ul {
  padding: 0;
  margin: 0;
  width: 100%;
  flex-wrap: wrap;
  background: linear-gradient(to right, #de4a67 1%, #7791c7 100%); }
  .mean-container .mean-nav ul li {
    position: relative;
    display: block;
    width: 100%; }

.mean-container .mean-nav ul li a {
  display: block;
  width: 100%;
  padding: 10px 15px;
  margin: 0;
  text-align: left;
  color: #fff;
  border-top: 1px solid #474747;
  text-decoration: none;
  text-transform: uppercase;
  background: rgba(255, 255, 255, 0.1); }

.mean-container .mean-nav ul li:first-child a {
  border-top: 1px solid transparent; }

.mean-container .mean-nav > ul > li a {
  position: relative; }

.mean-container .mean-nav > ul > li a:before {
  content: "\e905";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  display: none;
  font-family: icomoon !important;
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased; }

.mean-container .mean-nav > ul > li a:hover {
  text-decoration: underline;
  padding-left: 40px; }

.mean-container .mean-nav > ul > li a:hover:before {
  display: inline-block;
  left: 12px; }

.mean-container .mean-nav ul li li a {
  font-size: 12px !important;
  padding-left: 30px; }

.mean-nav .wrapper {
  width: 100%;
  padding: 0;
  margin: 0; }

.mean-remove {
  display: none !important; }

.icon-arrow-left,
.icon-arrow-right {
  font-size: 14px; }

label {
  font-size: 14px;
  font-weight: 400; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  transition: .3s; }

textarea {
  overflow: auto;
  resize: vertical;
  width: 100%;
  border: none;
  background: #f5f5f5;
  margin-bottom: 24px;
  padding: 16px 20px;
  min-height: 32px; }

input:not([type]),
input[type=date],
input[type=datetime-local],
input[type=email],
input[type=file],
input[type=number],
input[type=password],
input[type=search],
input[type=tel],
input[type="month"],
input[type=week],
input[type=text],
input[type=time],
input[type=url],
textarea,
select {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 100%;
  margin: 0;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 10px 14px;
  background: transparent;
  border: 1px solid #fff;
  color: #FFFFFF;
  box-shadow: none;
  border-radius: 3px; }

button[type=submit] {
  padding: 10px 14px; }

:-moz-placeholder,
::-moz-placeholder,
:-ms-input-placeholder,
::placeholder {
  color: #feb9c3;
  font-size: 14px; }

::-webkit-input-placeholder {
  color: #feb9c3; }

::-moz-selection,
::selection {
  background: #b3d4fc;
  text-shadow: none; }

.info-box span,
.info-box span i {
  display: inline-block; }

.tel-form .wrapp-form {
  max-width: 250px;
  margin: 0 auto;
  text-align: center; }

.form-group {
  margin: 0 0 22px; }
  .form-group.field-getexpertconsultationform-recaptcha, .form-group.field-getdetailsconsultationform-recaptcha {
    height: 74px;
    margin-left: -29px;
    transform: scale(0.78); }
    @media (max-width: 360px) {
      .form-group.field-getexpertconsultationform-recaptcha, .form-group.field-getdetailsconsultationform-recaptcha {
        margin-left: -40px;
        transform: scale(0.67); } }

.help-block {
  display: block;
  color: #669cd6;
  font-size: 12px; }

.btn-to-top {
  position: fixed;
  z-index: 100;
  display: none;
  bottom: 70px;
  right: 20px;
  font-size: 30px;
  cursor: pointer; }
  .btn-to-top.show {
    display: block; }
  .btn-to-top span {
    background-color: #fafafa;
    border: 1px solid #ee3f58;
    border-radius: 50%;
    display: inline-block;
    color: #1293d4;
    font-size: 16px;
    height: 50px;
    line-height: 50px;
    position: relative;
    text-align: center;
    transition: all .3s ease 0s;
    width: 50px;
    transform: rotate(180deg); }
  .btn-to-top:hover span {
    background-color: #ee3f58;
    color: #fff;
    border-color: transparent; }

table {
  display: block;
  overflow-x: auto;
  border-collapse: collapse;
  width: 100%;
  text-indent: 0;
  margin: 12px 0; }

table td, table th {
  border: 1px solid #dcd9d9;
  padding: 8px 10px;
  color: #333333;
  font-size: 14px; }

.table {
  display: table; }

.table-cell {
  display: table-cell;
  vertical-align: middle;
  float: none; }

#star-1:checked ~ section [for='star-1'] svg, #star-2:checked ~ section [for='star-1'] svg, #star-2:checked ~ section [for='star-2'] svg, #star-3:checked ~ section [for='star-1'] svg, #star-3:checked ~ section [for='star-2'] svg, #star-3:checked ~ section [for='star-3'] svg, #star-4:checked ~ section [for='star-1'] svg, #star-4:checked ~ section [for='star-2'] svg, #star-4:checked ~ section [for='star-3'] svg, #star-4:checked ~ section [for='star-4'] svg, #star-5:checked ~ section [for='star-1'] svg, #star-5:checked ~ section [for='star-2'] svg, #star-5:checked ~ section [for='star-3'] svg, #star-5:checked ~ section [for='star-4'] svg, #star-5:checked ~ section [for='star-5'] svg {
  transform: scale(1); }

#star-1:checked ~ section [for='star-1'] svg path, #star-2:checked ~ section [for='star-1'] svg path, #star-2:checked ~ section [for='star-2'] svg path, #star-3:checked ~ section [for='star-1'] svg path, #star-3:checked ~ section [for='star-2'] svg path, #star-3:checked ~ section [for='star-3'] svg path, #star-4:checked ~ section [for='star-1'] svg path, #star-4:checked ~ section [for='star-2'] svg path, #star-4:checked ~ section [for='star-3'] svg path, #star-4:checked ~ section [for='star-4'] svg path, #star-5:checked ~ section [for='star-1'] svg path, #star-5:checked ~ section [for='star-2'] svg path, #star-5:checked ~ section [for='star-3'] svg path, #star-5:checked ~ section [for='star-4'] svg path, #star-5:checked ~ section [for='star-5'] svg path {
  fill: #FFBB00;
  stroke: #cc9600; }

section.star-body {
  width: 300px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0); }

label.label-star {
  display: inline-block;
  width: 50px;
  text-align: center;
  cursor: pointer; }
  label.label-star svg {
    width: 100%;
    height: auto;
    fill: white;
    stroke: #CCC;
    transform: scale(0.8);
    transition: transform 200ms ease-in-out; }
    label.label-star svg path {
      transition: fill 200ms ease-in-out, stroke 100ms ease-in-out; }

label[for="star-null"] {
  display: block;
  margin: 0 auto;
  color: #999; }

.header {
  position: sticky;
  top: 0;
  z-index: 20; }
  .header_container {
    display: flex;
    align-items: center;
    justify-content: center; }
    @media (max-width: 1200px) {
      .header_container {
        justify-content: space-between; } }
    .header_container .logo {
      width: 214px;
      height: 39px; }
  .header-top {
    padding: 15px 0 30px 0;
    background: #fff;
    color: #424242; }
    .header-top:after {
      content: "";
      height: 10px;
      background: linear-gradient(to right, #de4a67 1%, #7791c7 100%);
      display: inline-block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0; }
  .header-search {
    margin-bottom: 15px; }
  .header .header-menu.mean-container {
    display: block; }
  @media screen and (max-width: 1200px) {
    .header .header-menu {
      display: none; } }

.parent-angle {
  font-size: 12px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%); }

#mainMenu .nav > li > a {
  padding: 0;
  padding-right: 16px;
  position: relative;
  display: block; }

#mainMenu .dropdown-menu {
  margin: 0;
  border-radius: 0 0 4px 4px;
  box-shadow: none;
  min-width: inherit;
  padding: 0;
  z-index: 9999999;
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  float: left;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15); }
  #mainMenu .dropdown-menu li {
    min-height: 45px;
    max-height: 45px;
    padding: 0; }
    #mainMenu .dropdown-menu li:hover {
      background-color: rgba(0, 0, 0, 0.1);
      cursor: pointer; }
    #mainMenu .dropdown-menu li > a {
      display: block;
      padding: 10px 20px;
      clear: both;
      font-weight: 400;
      line-height: 1.42857143;
      color: #333;
      white-space: nowrap; }
  #mainMenu .dropdown-menu a {
    font-size: 14px !important;
    display: flex !important;
    align-items: center;
    color: #333;
    padding: 10px 15px; }
    #mainMenu .dropdown-menu a .img-wrap {
      width: 20px;
      max-width: 20px;
      display: inline-block;
      margin-right: 10px; }
      #mainMenu .dropdown-menu a .img-wrap img {
        max-width: 100%;
        height: auto; }

#mainMenu li {
  text-align: left;
  padding: 10px 15px; }
  #mainMenu li.dropdown:hover .dropdown-menu {
    display: block; }
  #mainMenu li.dropdown .caret {
    display: none; }

.nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .nav > li,
  .nav > li > a {
    position: relative;
    display: block; }
  .nav__list {
    display: -ms-flexbox;
    display: flex;
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
    padding: 0 30px;
    margin: 0;
    list-style-type: none;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
    text-align: center; }
    .nav__list a {
      color: #474747;
      display: inline-block !important;
      font-size: 16px !important;
      text-transform: uppercase;
      box-sizing: border-box !important; }
      .nav__list a:focus {
        color: #474747;
        text-decoration: none;
        background-color: transparent !important; }
      .nav__list a:hover {
        color: #669cd6;
        text-decoration: none;
        background-color: transparent !important; }
    .nav__list.active a {
      color: #669cd6;
      text-decoration: none;
      background-color: transparent !important; }

@media screen and (max-width: 1200px) {
  nav.mean-nav li.dropdown .parent-angle {
    right: 18px; }
  nav.mean-nav li.dropdown .caret {
    display: none; }
  nav.mean-nav li.dropdown .dropdown-menu {
    position: static; }
    nav.mean-nav li.dropdown .dropdown-menu a .img-wrap {
      display: none; }
  nav.mean-nav li.dropdown a.mean-expand {
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0;
    width: 50px; } }

.nav__list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
  padding: 0 30px;
  margin: 0;
  list-style-type: none; }

.nav__list a {
  color: #474747;
  display: inline-block;
  font-size: 16px;
  text-transform: uppercase; }

.nav__list a:focus {
  color: #474747;
  text-decoration: none;
  background-color: transparent; }

.nav__list a:hover,
.nav__list .active a {
  color: #669cd6;
  text-decoration: none;
  background-color: transparent; }

.header-search #mainSearch {
  margin-top: 25px;
  position: relative; }
  .header-search #mainSearch .form-group {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 0; }
  .header-search #mainSearch .input-wrapper {
    position: relative;
    max-width: 975px;
    width: 100%;
    margin-right: 25px; }
  .header-search #mainSearch #js-search-main {
    height: 37px;
    border: 1px solid #7691c8;
    border-radius: 30px !important;
    color: #4a4a4a; }
  .header-search #mainSearch #js-search-main::placeholder {
    color: #4a4a4a; }
  .header-search #mainSearch #mainSearchSubmit {
    min-width: 135px;
    background-color: #f15169; }
    .header-search #mainSearch #mainSearchSubmit .icon-search {
      position: relative;
      left: -10px; }
  .header-search #mainSearch #js-search-main-scope {
    position: absolute;
    width: 100%;
    max-width: 975px;
    background: #fff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    z-index: 1; }
  @media (max-width: 767px) {
    .header-search #mainSearch {
      display: none; } }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding: 0 2px;
  margin-top: 20px; }
  @media (max-width: 767px) {
    .breadcrumb {
      padding: 16px 0 10px;
      flex-wrap: wrap;
      overflow: auto;
      height: 100%; } }
  .breadcrumb > li span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px; }
  @media (max-width: 767px) {
    .breadcrumb > li {
      display: inline;
      white-space: normal; } }
  .breadcrumb li:last-child a {
    color: #EF4F67; }

.breadcrumb .active {
  color: #e7445e; }

.breadcrumb li::before {
  content: '\003E';
  display: inline-block;
  color: #1293d4;
  margin-right: 2px;
  margin-left: 3px; }

.breadcrumb > li + li::after,
.breadcrumb li:first-child::before {
  display: none; }

@media (max-width: 478px) {
  .main-page .item .ncat {
    display: none; }
  .main-page .item {
    text-align: center; }
  .main-page .descr-news {
    display: block; }
  .main-page .descr-news p {
    width: 100%;
    padding-left: 0; } }

.main-page #get-expert-consultation-pjax {
  margin-top: 0;
  margin-bottom: 0;
  height: 100%;
  background: rgba(239, 62, 88, 0.9);
  padding: 40px 15px; }
  @media (max-width: 768px) {
    .main-page #get-expert-consultation-pjax {
      margin-left: 0;
      margin-right: 0; } }

.main-page .form-step2 {
  margin-top: 12px; }

.main-page .banner_container {
  display: flex;
  justify-content: center; }
  @media (max-width: 768px) {
    .main-page .banner_container {
      flex-wrap: wrap; } }

.main-page .banner_text {
  width: 100%;
  min-width: 418px;
  background-color: #fff;
  height: inherit;
  padding: 30px 25px; }
  @media (max-width: 768px) {
    .main-page .banner_text {
      width: 100%;
      min-width: inherit; } }
  .main-page .banner_text .title-saminormal {
    font-size: 36px;
    line-height: 40px;
    color: #ef3e58;
    font-weight: 700;
    margin-bottom: 40px; }
    .main-page .banner_text .title-saminormal span {
      display: block;
      text-transform: uppercase;
      font-size: 36px;
      line-height: 40px;
      color: #ef3e58;
      font-weight: 700; }
  .main-page .banner_text p {
    font-size: 24px;
    font-weight: 700;
    color: #409dd9; }
    .main-page .banner_text p:last-child {
      color: #ef3e58; }
  @media (max-width: 480px) {
    .main-page .banner_text {
      text-align: center; } }

.main-page .banner_form {
  width: calc(40% - 30px);
  min-width: 280px;
  margin-left: 30px; }
  @media (max-width: 768px) {
    .main-page .banner_form {
      width: 100%;
      min-width: inherit;
      margin-left: 0; } }

.article_container {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: flex-start; }
  @media (max-width: 992px) {
    .article_container {
      flex-wrap: wrap; } }

.article_content {
  width: calc(75% - 20px);
  min-width: 680px;
  margin-right: 20px;
  background-color: #fff;
  padding: 15px; }
  @media (max-width: 992px) {
    .article_content {
      width: 100%;
      min-width: inherit;
      margin-right: 0; } }
  .article_content_right {
    margin-right: auto;
    background-color: transparent; }
  .article_content .post-img {
    min-height: 70px; }

.article_sidebar {
  width: 25%;
  min-width: 280px;
  position: sticky;
  top: 124px; }
  @media (max-width: 992px) {
    .article_sidebar {
      width: 100%;
      min-width: inherit;
      position: relative;
      top: 0; } }

.article_author {
  display: flex;
  margin: 10px 0 25px;
  justify-content: center; }
  @media (max-width: 768px) {
    .article_author {
      flex-direction: column;
      align-items: center; } }
  .article_author .author_info {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    min-width: 250px;
    position: relative; }
    @media (max-width: 768px) {
      .article_author .author_info {
        min-width: inherit;
        margin-bottom: 15px;
        margin-right: auto; } }
    .article_author .author_info:after {
      content: '';
      width: 1px;
      height: 80%;
      background-color: #49546C;
      position: absolute;
      right: 0;
      top: 10%; }
      @media (max-width: 768px) {
        .article_author .author_info:after {
          display: none; } }
  .article_author .author_img {
    width: 40px;
    min-width: 40px;
    height: 40px;
    min-height: 40px;
    border-radius: 50%;
    margin-right: 15px; }
    .article_author .author_img img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%; }
  .article_author .author_content {
    display: flex;
    flex-direction: column;
    margin-right: 20px; }
  .article_author .author_name {
    font-weight: 500;
    font-size: 14px;
    color: #409DD9; }

.article_publication {
  display: flex;
  align-items: center; }
  @media (max-width: 768px) {
    .article_publication {
      width: 100%;
      justify-content: center;
      flex-direction: column;
      align-items: flex-start; } }
  .article_publication p {
    margin-bottom: 0;
    margin-right: 7px;
    font-size: 14px !important;
    text-indent: 0 !important;
    margin-left: 20px; }
    @media (max-width: 768px) {
      .article_publication p {
        margin: 0 !important; } }

.item-article {
  padding: 20px;
  background-color: #fff;
  font-size: 16px;
  color: #49546c;
  margin-bottom: 20px; }
  .item-article .top-nblock {
    margin-bottom: 10px; }
  .item-article p {
    text-indent: 30px;
    font-size: 16px;
    color: #49546c; }
  .item-article ul {
    padding-left: 30px; }
    .item-article ul li {
      padding-left: 25px;
      position: relative;
      margin-bottom: 15px; }
  .item-article ol {
    list-style-type: none;
    counter-reset: num;
    padding-left: 0; }
    .item-article ol li {
      margin-bottom: 20px;
      text-indent: 30px; }
      .item-article ol li::before {
        content: counter(num) ". ";
        counter-increment: num;
        color: #4f86fe; }
    .item-article ol ul {
      margin-top: 10px; }
      .item-article ol ul li::before {
        content: "";
        counter-increment: b; }
  .item-article .description-news {
    word-break: normal; }
    .item-article .description-news table {
      margin: 12px auto !important;
      display: table !important; }
    .item-article .description-news .article-image {
      width: 300px;
      float: right;
      margin-left: 10px; }
      @media (max-width: 1199px) {
        .item-article .description-news .article-image {
          display: block;
          float: unset;
          margin-left: auto;
          margin-right: auto; } }

.main-page .article_content {
  width: 100%;
  margin: 50px 0 70px 0;
  background-color: inherit; }

.content-post p {
  color: #49546c;
  font-weight: 500; }

.content-post li::before {
  display: none !important; }

.content-post li {
  list-style-type: disc;
  padding-left: 15px !important; }
  .content-post li a {
    font-size: 16px !important; }

.content-post ul {
  margin-left: 30px; }

.section-comment {
  padding: 20px; }
  .section-comment .comment-list .comment-layout .comment_head {
    display: flex;
    flex-direction: column;
    align-items: flex-end; }
  .section-comment .comment-list .comment-layout .comment-body {
    color: #fff;
    padding: 15px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px; }
  .section-comment .comment-list .question-comment {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 12px; }
    .section-comment .comment-list .question-comment .question-comment-layout .comment_head {
      align-items: flex-start; }
    .section-comment .comment-list .question-comment .question-comment-layout .comment-body {
      background-color: rgba(238, 63, 88, 0.7);
      border-top-right-radius: 20px; }
  .section-comment .comment-list .answer-comment {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 12px; }
    .section-comment .comment-list .answer-comment .answer-comment-layout .comment-body {
      background-color: rgba(102, 156, 214, 0.7);
      border-top-left-radius: 20px; }
  .section-comment .comment-form {
    box-shadow: 0 0 17px 1px rgba(75, 75, 75, 0.11);
    padding: 30px;
    margin: 20px 0; }
    @media screen and (max-width: 768px) {
      .section-comment .comment-form {
        padding: 12px;
        margin: 12px; } }
    .section-comment .comment-form .comments-form-title {
      color: #669cd6;
      margin-bottom: 20px;
      text-align: center; }
    .section-comment .comment-form .form-set {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap; }
      .section-comment .comment-form .form-set .form-group {
        max-width: 48%;
        margin: 0 0 14px; }
        @media screen and (max-width: 768px) {
          .section-comment .comment-form .form-set .form-group {
            max-width: 100%; } }
    .section-comment .comment-form .form-set-bottom {
      justify-content: center; }
      .section-comment .comment-form .form-set-bottom > div:first-child {
        height: 90px; }
    .section-comment .comment-form .form-group {
      width: 100%;
      text-align: center; }
      .section-comment .comment-form .form-group input,
      .section-comment .comment-form .form-group textarea,
      .section-comment .comment-form .form-group select {
        background-color: #F4F4F4 !important;
        border-color: transparent;
        border-radius: 0;
        box-shadow: none;
        color: #474747; }
        .section-comment .comment-form .form-group input::placeholder,
        .section-comment .comment-form .form-group textarea::placeholder,
        .section-comment .comment-form .form-group select::placeholder {
          color: #474747; }
      .section-comment .comment-form .form-group textarea {
        resize: none; }
    .section-comment .comment-form .field-commentmodel-recaptcha {
      margin-left: 0;
      transform: scale(0.78); }
      @media (max-width: 360px) {
        .section-comment .comment-form .field-commentmodel-recaptcha {
          transform: scale(0.67); } }
      @media screen and (max-width: 768px) {
        .section-comment .comment-form .field-commentmodel-recaptcha .g-recaptcha > div {
          margin: 0 auto; } }

.select-doc {
  padding: 14px; }
  .select-doc .h-title {
    display: block;
    margin: 25px 0 40px 0;
    color: #409DD9;
    font-size: 28px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase; }
  .select-doc .hint {
    display: flex;
    align-items: center;
    padding: 35px 30px;
    border: 1px dashed #409DD9;
    margin-bottom: 40px; }
    @media screen and (max-width: 767px) {
      .select-doc .hint {
        flex-direction: column; } }
    .select-doc .hint-icon {
      display: block;
      min-width: 34px;
      height: 34px;
      margin: 0 20px;
      background: url("/img/hint-icon.png") no-repeat;
      background-size: contain; }
      @media screen and (max-width: 767px) {
        .select-doc .hint-icon {
          margin-bottom: 10px;
          margin-left: 0; } }
    .select-doc .hint-right {
      display: flex;
      flex-direction: column; }
    .select-doc .hint-text {
      color: #000;
      font-size: 18px; }
      .select-doc .hint-text:first-of-type {
        margin-bottom: 10px; }
  .select-doc .steps {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    counter-reset: step;
    padding: 0; }
    .select-doc .steps-item {
      position: relative;
      background-color: #fff;
      color: #585858;
      font-size: 14px;
      cursor: auto;
      max-width: 186px;
      white-space: inherit;
      border: 0;
      list-style: none;
      width: 100%;
      text-align: center;
      padding: 10px;
      user-select: none;
      pointer-events: none; }
      .select-doc .steps-item:before {
        counter-increment: step;
        content: "Шаг " counter(step);
        display: block;
        font-size: 18px;
        margin-bottom: 4px;
        color: #409DD9; }
      .select-doc .steps-item:last-child:before {
        content: "Результат"; }
    .select-doc .steps .active-step {
      color: #fff;
      cursor: pointer;
      opacity: 0.6;
      pointer-events: auto; }
      .select-doc .steps .active-step:before {
        color: #fff; }
      .select-doc .steps .active-step:nth-child(1) {
        background-color: #DE4A67; }
        .select-doc .steps .active-step:nth-child(1):after {
          border-top-color: #DE4A67; }
      .select-doc .steps .active-step:nth-child(2) {
        background-color: #C15F82; }
        .select-doc .steps .active-step:nth-child(2):after {
          border-top-color: #C15F82; }
      .select-doc .steps .active-step:nth-child(3) {
        background-color: #AC6D95; }
        .select-doc .steps .active-step:nth-child(3):after {
          border-top-color: #AC6D95; }
      .select-doc .steps .active-step:nth-child(4) {
        background-color: #9080B0; }
        .select-doc .steps .active-step:nth-child(4):after {
          border-top-color: #9080B0; }
      .select-doc .steps .active-step:nth-child(5) {
        background-color: #7791C7; }
        .select-doc .steps .active-step:nth-child(5):after {
          border-top-color: #7791C7; }
    .select-doc .steps .current-step {
      opacity: 1;
      color: #fff;
      pointer-events: none; }
      .select-doc .steps .current-step:before {
        color: #fff; }
      .select-doc .steps .current-step:after {
        content: "";
        display: block;
        position: absolute;
        bottom: -110px;
        left: 0;
        width: 100%;
        border: 90px solid transparent;
        border-top: 20px solid; }
  .select-doc .step-info {
    display: flex;
    flex-wrap: wrap;
    font-weight: 700; }
    .select-doc .step-info-list {
      margin-bottom: 20px;
      flex-grow: 1; }
      .select-doc .step-info-list ul {
        list-style: none;
        padding: 0;
        margin: 0;
        position: relative; }
      .select-doc .step-info-list p {
        font-size: 16px;
        font-weight: 400;
        cursor: initial;
        text-decoration: none; }
        .select-doc .step-info-list p:first-child {
          margin-top: 16px; }
      .select-doc .step-info-list-title {
        display: block;
        padding-left: 45px;
        margin-bottom: 10px;
        font-size: 18px;
        color: #409DD9; }
    .select-doc .step-info-5 ul {
      counter-reset: num;
      padding-left: 15px; }
      .select-doc .step-info-5 ul li {
        position: relative; }
        .select-doc .step-info-5 ul li:before {
          counter-increment: num;
          content: counter(num) ".";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          font-size: 16px;
          margin-bottom: 5px;
          color: #409DD9; }
    .select-doc .step-info-5 .step-info-sidebar .final-text {
      color: #409DD9;
      font-size: 18px;
      margin-bottom: 1rem; }
    .select-doc .step-info-5 .step-info-sidebar .btn {
      position: relative;
      padding-left: 60px;
      white-space: inherit; }
      .select-doc .step-info-5 .step-info-sidebar .btn:before {
        content: "";
        position: absolute;
        background: url("/img/details-document.png") no-repeat;
        background-size: contain;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        height: 24px; }
    .select-doc .step-info .image-holder {
      max-width: 200px;
      padding: 2rem; }
      .select-doc .step-info .image-holder img {
        max-width: 100%;
        height: auto; }
    .select-doc .step-info ul li p {
      padding-left: 20px; }
    .select-doc .step-info ul li a {
      display: block;
      position: relative;
      padding: 10px 0 10px 45px;
      color: #474747;
      font-size: 16px;
      font-weight: 400;
      cursor: pointer; }
      .select-doc .step-info ul li a:hover {
        color: #fff;
        background: linear-gradient(to right, #de4a67 1%, #7791c7 100%); }
        .select-doc .step-info ul li a:hover:before {
          content: "\e905";
          position: absolute;
          left: 12px;
          top: 50%;
          transform: translateY(-50%);
          color: #fff;
          font-family: icomoon !important;
          speak: none;
          font-style: normal;
          font-weight: 400;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased; }
    .select-doc .step-info .step-info-sidebar {
      width: 100%; }

@media screen and (max-width: 1199px) {
  .select-doc .steps-item span {
    display: none; } }

@media screen and (max-width: 992px) {
  .select-doc .steps {
    flex-wrap: wrap;
    border-bottom: 0;
    justify-content: center;
    flex-direction: column;
    align-items: center; }
    .select-doc .steps-item {
      flex-basis: calc(100% - 20px);
      white-space: normal;
      margin-bottom: 30px; }
      .select-doc .steps-item span {
        display: inline-block; }
  .select-doc .step-info .image-holder {
    display: none; } }

@media screen and (max-width: 480px) {
  .select-doc .steps-item {
    font-size: 16px; } }

.sidebar-postlists li {
  margin-bottom: 25px; }

.article_sidebar .sidebar-banner .banner-layout {
  text-align: center;
  background: #409DD9;
  padding: 30px 20px;
  font-weight: 500; }
  .article_sidebar .sidebar-banner .banner-layout .title {
    font-size: 16px;
    text-transform: uppercase;
    color: #FFFFFF; }
  .article_sidebar .sidebar-banner .banner-layout .description {
    font-size: 14px;
    color: #FFFFFF; }
  .article_sidebar .sidebar-banner .banner-layout .cards {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -4px; }
    .article_sidebar .sidebar-banner .banner-layout .cards .card {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      margin: 4px;
      width: 100%;
      max-width: calc(50% - 8px);
      background: #FFFFFF;
      padding: 18px 6px;
      min-height: 114px; }
      .article_sidebar .sidebar-banner .banner-layout .cards .card .card-icon {
        max-width: 50px; }
      .article_sidebar .sidebar-banner .banner-layout .cards .card .card-title {
        color: #409DD9;
        font-size: 13px;
        margin: 0; }
  .article_sidebar .sidebar-banner .banner-layout .btn {
    margin-top: 24px;
    min-width: 190px; }

.sidebar-upload-order {
  max-width: 300px;
  margin: 30px auto 20px; }
  .sidebar-upload-order a {
    position: relative;
    display: inline-block;
    background-color: #ef3e58e6;
    color: #fff;
    padding: 14px 64px 14px 18px;
    width: 100%;
    text-align: left;
    text-transform: uppercase; }
    .sidebar-upload-order a img {
      position: absolute;
      top: -10px;
      right: 10px;
      max-width: 64px; }

.custom_tabs .nav-tabs {
  padding-left: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  border-bottom: 1px solid #ddd; }
  @media (max-width: 768px) {
    .custom_tabs .nav-tabs {
      flex-direction: column;
      align-items: center; } }
  .custom_tabs .nav-tabs li {
    padding-left: 0;
    width: auto;
    min-width: 200px;
    flex-grow: 1; }
    @media (max-width: 768px) {
      .custom_tabs .nav-tabs li {
        width: 100%; } }
    .custom_tabs .nav-tabs li a:before {
      display: none; }
    .custom_tabs .nav-tabs li.active a,
    .custom_tabs .nav-tabs li.active a:focus,
    .custom_tabs .nav-tabs li.active a:hover {
      background-color: #f15169;
      padding-top: 5px;
      border-radius: 4px 4px 0 0;
      color: #555;
      cursor: default; }
  .custom_tabs .nav-tabs a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 5px 10px;
    border-bottom: 1px solid #f15169; }

.custom_tabs .tab-content > .active {
  display: block; }

.custom_tabs .tab-pane {
  display: none; }

.accordeon .panel-group {
  margin-bottom: 20px; }
  .accordeon .panel-group .panel {
    margin-bottom: 0;
    border-radius: 4px;
    border-color: #ddd;
    background-color: #fff;
    border: 1px solid transparent;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); }
    .accordeon .panel-group .panel-heading {
      color: #333;
      background-color: #f5f5f5;
      border-color: #ddd;
      border-bottom: 0;
      padding: 10px 15px;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      cursor: pointer; }
    .accordeon .panel-group .panel-title {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 16px;
      position: relative; }
      .accordeon .panel-group .panel-title:after {
        font-family: icomoon !important;
        position: absolute;
        right: 0;
        content: "";
        transition: all 0.5s ease; }
    .accordeon .panel-group .panel-body {
      border-top-color: #ddd;
      border-top: 1px solid #ddd;
      padding: 15px; }

.accordeon .panel .active .panel-title:after {
  transform: rotate(180deg); }

.accordeon .collapse {
  display: none; }
  .accordeon .collapse.show {
    display: block; }

footer.footer {
  background: #0e1a35;
  color: #fff;
  margin-top: 20px; }
  footer.footer .bottom-section {
    background: url("/img/bg-formbottom.jpg") no-repeat 50% 50%;
    background-size: cover; }
    footer.footer .bottom-section p {
      color: #fff;
      font-size: 18px; }
    footer.footer .bottom-section .wrap-formbs {
      max-width: 570px;
      margin: 0 auto;
      padding: 56px 10px;
      text-align: center; }
    footer.footer .bottom-section form {
      display: flex;
      align-items: center;
      flex-wrap: wrap; }
      footer.footer .bottom-section form > .form-group {
        width: 100%;
        max-width: calc(50% - 24px);
        margin: 0 12px;
        text-align: left; }
        @media screen and (max-width: 768px) {
          footer.footer .bottom-section form > .form-group {
            max-width: 100%; } }
      footer.footer .bottom-section form label {
        color: #fff;
        font-size: 16px; }
      footer.footer .bottom-section form input,
      footer.footer .bottom-section form select {
        color: #fff;
        background: transparent;
        border: 1px solid #fff; }
    footer.footer .bottom-section .form-button {
      width: 100%; }
    footer.footer .bottom-section .form-control option {
      background: rgba(239, 62, 88, 0.9); }
    footer.footer .bottom-section .help-block {
      color: #f00 !important; }
  footer.footer .footer-bottom-menu {
    display: flex;
    align-items: center;
    padding: 25px 0;
    justify-content: space-between;
    flex-wrap: wrap; }
    @media screen and (max-width: 767px) {
      footer.footer .footer-bottom-menu {
        flex-direction: column;
        text-align: center; } }
    footer.footer .footer-bottom-menu__left, footer.footer .footer-bottom-menu__left {
      display: flex;
      flex-direction: column;
      width: 37%; }
      @media screen and (max-width: 767px) {
        footer.footer .footer-bottom-menu__left, footer.footer .footer-bottom-menu__left {
          width: 100%; } }
    footer.footer .footer-bottom-menu .top-content {
      display: flex;
      align-items: center;
      justify-content: space-between; }
    footer.footer .footer-bottom-menu__left .top-content {
      margin-bottom: 35px; }
    footer.footer .footer-bottom-menu__left .bottom-content > span {
      font-size: 10px;
      color: #f4f4f4;
      opacity: 0.6; }
    footer.footer .footer-bottom-menu__right .top-content {
      margin-bottom: 11px; }
      @media screen and (max-width: 767px) {
        footer.footer .footer-bottom-menu__right .top-content {
          flex-direction: column; } }
    footer.footer .footer-bottom-menu__right .bottom-content .hide-textm {
      font-size: 10px;
      font-weight: 700; }
    footer.footer .footer-bottom-menu .footer-callback a,
    footer.footer .footer-bottom-menu .footer-faq a,
    footer.footer .footer-bottom-menu .footer-news a {
      font-weight: bold;
      font-size: 16px;
      color: #EF4F67;
      text-transform: uppercase; }
    @media screen and (max-width: 767px) {
      footer.footer .footer-bottom-menu .footer-callback {
        margin: 15px 0; } }
    footer.footer .footer-bottom-menu .footer-callback p {
      margin-bottom: 0;
      color: #F4F4F4;
      text-transform: uppercase;
      font-size: 10px; }
    footer.footer .footer-bottom-menu .footer-callback .sitemap {
      font-size: 12px;
      font-weight: 400;
      color: #F4F4F4; }
    footer.footer .footer-bottom-menu .footer-socials {
      width: 98px;
      display: flex;
      justify-content: space-between; }
      footer.footer .footer-bottom-menu .footer-socials img {
        max-width: 40px; }
      footer.footer .footer-bottom-menu .footer-socials a {
        max-width: 25px;
        max-height: 25px; }
    footer.footer .footer-bottom-menu .logo {
      width: 150px;
      height: 28px; }

.pagination {
  display: flex;
  flex-wrap: wrap; }
  .pagination .first,
  .pagination .last {
    display: none; }
  .pagination li {
    display: flex;
    align-items: center;
    vertical-align: middle;
    font-size: 12px;
    padding: 0 12px;
    background-color: transparent; }
  .pagination .prev i,
  .pagination .next i {
    color: #669cd6;
    font-size: 12px; }
  .pagination .active a {
    color: #000;
    pointer-events: none; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 50;
  display: none;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  background-color: rgba(0, 0, 0, 0.7);
  overflow: hidden;
  opacity: 0;
  transition: opacity .25s linear;
  height: 100dvh !important; }
  .modal.in {
    opacity: 1; }
  .modal-dialog {
    max-width: 540px;
    margin: 30px auto;
    position: relative; }
    .modal-dialog--reviews {
      margin-top: 30vh;
      max-width: 723px; }
  .modal-content {
    background: #f4f4f4;
    padding: 25px 15px;
    border-radius: 9px;
    position: relative;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    outline: 0; }
    .modal-content-app {
      background-color: transparent;
      border-radius: 0;
      padding: 0; }
    .modal-content--reviews {
      background-color: #fff;
      width: 723px;
      padding: 34px 34px 18px 34px;
      border: 1px solid #ee3f58;
      border-radius: 0; }
      .modal-content--reviews .m-wrapp {
        max-width: 100% !important; }
      .modal-content--reviews .form-group {
        margin-bottom: 16px; }
      .modal-content--reviews .modal-title {
        font-weight: 400;
        text-align: left !important; }
      .modal-content--reviews .modal-text {
        font-size: 17px; }
      .modal-content--reviews .modal-body .modal--review {
        border: 1px solid #8F9395;
        font-size: 17px;
        border-radius: 0px; }
      .modal-content--reviews .modal--review::placeholder {
        color: #8F9395; }
      .modal-content--reviews .modal-footer {
        display: flex;
        gap: 16px;
        align-items: center; }
        .modal-content--reviews .modal-footer p {
          display: block;
          margin: 0;
          font-size: 14px;
          text-align: left;
          color: #000; }
      .modal-content--reviews button[type="submit"] {
        padding: 10px 34px;
        font-size: 16px; }
      .modal-content--reviews .overlay__close {
        top: 10px;
        right: 10px; }
        .modal-content--reviews .overlay__close span:after,
        .modal-content--reviews .overlay__close span:before {
          left: 6px;
          width: 14px;
          height: 1px; }
  .modal .m-wrapp {
    max-width: 330px;
    margin: 0 auto; }
  .modal .modal-title {
    text-align: center;
    font-size: 24px; }
  .modal .modal-border {
    border: 1px dotted #4e608d;
    border-radius: 8px;
    padding: 35px 20px 20px;
    text-align: center; }
  .modal .modal-header {
    border-bottom: 0;
    padding: 0; }
  .modal .modal-body label {
    color: #747474;
    font-size: 14px;
    border-radius: 15px;
    margin-bottom: 5px;
    padding-top: 0;
    border: 0;
    display: block; }
  .modal .modal-body input {
    margin-bottom: 0;
    border-radius: 3px;
    padding: 10px;
    border: 0;
    background-color: #fff;
    color: #000; }
  .modal .modal-body textarea {
    width: 100%;
    max-width: 100%;
    border-radius: 3px;
    height: 122px;
    border: 0;
    background-color: #fff;
    margin-bottom: 0;
    color: #000; }
  .modal .modal-body .has-error input,
  .modal .modal-body .has-error textarea {
    background-color: #ccc !important;
    border-radius: 3px; }
  .modal .modal-body .field-contactform-recaptchacontactus,
  .modal .modal-body .field-contactform-recaptcha {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
  .modal .modal-body .field-contactform-phone {
    margin-bottom: 12px; }
  .modal .modal-body .phone-tooltip {
    font-size: 14px; }
  .modal .modal-footer {
    border-top: 0;
    text-align: center; }
    .modal .modal-footer p {
      color: #a7a7a7; }
  .modal .btn-closex {
    top: 10px;
    right: 30px;
    background: transparent;
    border: 0;
    padding: 0; }
    .modal .btn-closex span:before, .modal .btn-closex span:after {
      background: #fff; }

.overlay__close {
  position: absolute;
  top: 21px;
  right: 21px;
  width: 25px;
  height: 25px;
  opacity: 1 !important;
  padding: 0;
  background-color: transparent;
  border: 0;
  z-index: 200; }

.overlay__close:hover {
  opacity: 0.7 !important; }

.overlay__close span {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative; }

.overlay__close span:before,
.overlay__close span:after {
  content: "";
  width: 25px;
  height: 4px;
  background: #c7c7c7;
  display: block;
  border-radius: 1px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateX(-50%) translateY(-50%); }

.overlay__close span:before {
  transform: rotate(45deg); }

.overlay__close span:after {
  transform: rotate(-45deg); }

.alert {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.6); }
  .alert.alert-success {
    color: #008000; }
  .alert.alert-error {
    color: #ff0000; }
  .alert .alert-message {
    position: absolute;
    background-color: #fff;
    padding: 30px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

@media screen and (max-width: 767px) {
  #CalltouchWidgetFrame .hlSVWR {
    width: 100% !important;
    bottom: 0 !important; }
  #CalltouchWidgetFrame .caVnsn {
    background: none !important; } }

.review-button-container {
  position: fixed;
  top: 380px;
  transform: rotate(-90deg) translateY(-70px); }

@media (max-width: 992px) {
  .review-button-container {
    display: none; } }

.review-button {
  padding: 10px 19px;
  border-top-right-radius: 0;
  border-top-left-radius: 0; }

.short-code-replacer-hr {
  text-align: center;
  padding: 20px;
  overflow: hidden; }
  .short-code-replacer-hr .short-code-replacer-hr-button {
    position: relative; }
    .short-code-replacer-hr .short-code-replacer-hr-button:before, .short-code-replacer-hr .short-code-replacer-hr-button:after {
      position: absolute;
      content: '';
      height: 1px;
      background-color: #49546c;
      width: 400px;
      top: 20px; }
    .short-code-replacer-hr .short-code-replacer-hr-button:before {
      left: 110%; }
    .short-code-replacer-hr .short-code-replacer-hr-button:after {
      right: 110%; }

.header-banner {
  background: #fff; }
  .header-banner .wrapper {
    position: relative; }
    @media screen and (min-width: 992px) {
      .header-banner .wrapper::after {
        content: url("/img/lines-add.png");
        position: absolute;
        right: 0;
        z-index: 1;
        top: 0; } }
    .header-banner .wrapper::before {
      content: url("/img/lines.png");
      position: absolute;
      left: 8%;
      z-index: 1; }
  .header-banner .banner {
    background: linear-gradient(90deg, #df4a67 0%, #7991c6 100%);
    height: 90px;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center; }
    .header-banner .banner:before {
      content: url("/img/back2.png");
      position: absolute;
      top: 0; }
    .header-banner .banner .banner-partner {
      background: url("/img/back.png");
      position: relative;
      left: 4px;
      top: -10px;
      width: 266px;
      height: 90px; }
      .header-banner .banner .banner-partner::after {
        content: url("/img/stars.png");
        position: absolute;
        left: 25px;
        top: 25px; }
      .header-banner .banner .banner-partner__title {
        color: #f4f4f4;
        font-weight: 700;
        text-align: right;
        margin: 0;
        position: absolute;
        top: 20px;
        left: 15px;
        font-size: 11px; }
      .header-banner .banner .banner-partner__img {
        position: absolute;
        bottom: 10px;
        right: 60px;
        z-index: 9; }
    .header-banner .banner .banner-offer {
      display: flex;
      gap: 35px;
      align-items: center;
      margin-left: 35px; }
      @media screen and (max-width: 991px) {
        .header-banner .banner .banner-offer {
          display: none; } }
      .header-banner .banner .banner-offer__title {
        color: rgba(244, 244, 244, 0.71);
        font-size: 15px;
        text-align: right; }
      .header-banner .banner .banner-offer p {
        font-size: 23px;
        color: #f4f4f4;
        margin: 0;
        font-family: 'Russo One', sans-serif; }
        @media screen and (min-width: 992px) and (max-width: 1199px) {
          .header-banner .banner .banner-offer p {
            font-size: 20px; } }
        .header-banner .banner .banner-offer p .banner-btn {
          background: #f4f4f4;
          border-radius: 16.48px;
          font-size: 9px;
          font-weight: 700;
          padding: 7px 10px;
          color: #424242;
          text-transform: uppercase;
          margin-left: 10px;
          z-index: 9;
          position: relative;
          line-height: 1;
          border: none; }
          .header-banner .banner .banner-offer p .banner-btn span {
            font-size: 8px;
            color: #424242;
            font-weight: 700; }
            .header-banner .banner .banner-offer p .banner-btn span:first-child {
              margin-left: 5px; }
            .header-banner .banner .banner-offer p .banner-btn span:last-child {
              margin-left: -5px; }

.service-menu {
  background: #fff;
  padding: 15px 0 10px 0; }
  @media screen and (max-width: 767px) {
    .service-menu {
      padding: 15px 0 30px 0; } }
  .service-menu .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media screen and (max-width: 767px) {
      .service-menu .wrapper {
        flex-direction: column; } }
  .service-menu .menu-items a:not(:last-child) {
    margin-right: 40px; }
    @media (max-width: 767px) {
      .service-menu .menu-items a:not(:last-child) {
        margin-right: 25px; } }
  .service-menu .menu-items a {
    color: #424242;
    font-size: 12px; }
    .service-menu .menu-items a:hover {
      color: #669cd6; }
  .service-menu .menu-items .legal-banking-services {
    display: none; }
    @media (max-width: 767px) {
      .service-menu .menu-items .legal-banking-services {
        display: unset; } }
  .service-menu .service-items a {
    font-weight: 600;
    font-style: italic;
    color: #f4f4f4;
    display: inline-block;
    font-size: 12px; }
    .service-menu .service-items a > span {
      transform: skew(20deg);
      display: inline-block; }
  .service-menu .service-items .rect {
    padding: 10px 23px;
    background: #4b9bce;
    transform: skewX(-20deg); }
  @media screen and (max-width: 767px) {
    .service-menu .service-items {
      display: none; } }

.team-item {
  background: #6099C9;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  max-width: 248px;
  height: 390px;
  margin: 25px 0; }
  @media screen and (max-width: 767px) {
    .team-item {
      max-width: 340px;
      width: 100%;
      margin: 15px 0; } }
  .team-item__image {
    overflow: hidden;
    height: 282px;
    display: flex;
    justify-content: center; }
  .team-item__image img {
    max-width: initial;
    scale: 1.2; }
  .team-item__description {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    justify-items: center;
    height: 101px;
    padding: 0 12px; }
    @media screen and (max-width: 767px) {
      .team-item__description {
        padding: 8px 50px; } }
  .team-item__name, .team-item__position {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    display: flex;
    justify-content: center;
    color: #fff; }

#team-member-page {
  background: #fff;
  margin-top: 20px;
  padding: 32px 20px 21px 20px; }
  #team-member-page .team-member-page__container {
    display: flex;
    flex-direction: row; }
    @media screen and (max-width: 767px) {
      #team-member-page .team-member-page__container {
        flex-direction: column; } }
    #team-member-page .team-member-page__container__image {
      overflow: hidden;
      height: 482px;
      width: 442px;
      display: flex;
      justify-content: center; }
      @media screen and (max-width: 767px) {
        #team-member-page .team-member-page__container__image {
          height: 227px;
          width: auto; } }
      #team-member-page .team-member-page__container__image img {
        max-width: initial; }
    #team-member-page .team-member-page__container__content {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      margin-left: 20px;
      width: 58%; }
      @media screen and (max-width: 767px) {
        #team-member-page .team-member-page__container__content {
          width: 100%;
          margin: 0; } }
      #team-member-page .team-member-page__container__content__head {
        height: 94px; }
        @media screen and (max-width: 767px) {
          #team-member-page .team-member-page__container__content__head {
            margin: 10px 0;
            height: 80px; } }
        #team-member-page .team-member-page__container__content__head-content {
          display: flex;
          justify-content: space-around;
          flex-direction: column;
          text-align: center;
          color: #FFFFFF;
          line-height: 28px;
          background: #6099C9;
          transform: skew(-25deg);
          margin: 0 22px;
          height: 100%;
          padding-top: 10px; }
          @media screen and (max-width: 767px) {
            #team-member-page .team-member-page__container__content__head-content {
              padding: 15px 0; } }
          #team-member-page .team-member-page__container__content__head-content .team-member__name {
            font-weight: 700;
            transform: skew(25deg);
            font-size: 24px; }
            @media screen and (max-width: 767px) {
              #team-member-page .team-member-page__container__content__head-content .team-member__name {
                font-size: 18px; } }
          #team-member-page .team-member-page__container__content__head-content .team-member__position {
            font-weight: 500;
            transform: skew(25deg);
            font-size: 24px;
            font-family: Roboto serif; }
            @media screen and (max-width: 767px) {
              #team-member-page .team-member-page__container__content__head-content .team-member__position {
                font-size: 18px; } }
      #team-member-page .team-member-page__container__content__feature {
        color: #000000;
        display: flex;
        flex-direction: column;
        text-align: left; }
        @media screen and (max-width: 767px) {
          #team-member-page .team-member-page__container__content__feature {
            margin-top: 15px;
            text-align: center; } }
        #team-member-page .team-member-page__container__content__feature div {
          display: flex; }
          @media screen and (max-width: 767px) {
            #team-member-page .team-member-page__container__content__feature div {
              display: inline; } }
        #team-member-page .team-member-page__container__content__feature-name {
          font-weight: 700;
          font-size: 24px;
          border-bottom: 3px solid #f15169;
          padding-bottom: 20px; }
          @media screen and (max-width: 767px) {
            #team-member-page .team-member-page__container__content__feature-name {
              font-size: 18px;
              padding-bottom: 10px; } }
        #team-member-page .team-member-page__container__content__feature-value {
          font-size: 20px;
          line-height: 23px;
          font-weight: 500;
          margin-top: 20px; }
          @media screen and (max-width: 767px) {
            #team-member-page .team-member-page__container__content__feature-value {
              font-size: 16px; } }
  #team-member-page .team-member-page__back-button {
    margin-top: 45px; }
    @media screen and (max-width: 767px) {
      #team-member-page .team-member-page__back-button {
        text-align: center;
        margin-top: 30px; } }
    #team-member-page .team-member-page__back-button a {
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      color: #F15169; }
  #team-member-page .team-member-page__articles {
    margin-top: 2rem; }
    #team-member-page .team-member-page__articles h2 {
      text-align: center; }
    #team-member-page .team-member-page__articles li {
      display: flex;
      gap: 1rem;
      align-items: center;
      margin-top: 0.5rem; }
      #team-member-page .team-member-page__articles li span {
        font-size: 1.3rem; }
      #team-member-page .team-member-page__articles li a {
        font-size: 1.3rem; }
        #team-member-page .team-member-page__articles li a:hover {
          color: #097db7; }
        #team-member-page .team-member-page__articles li a > h5 {
          margin: 0 !important; }

@media screen and (max-width: 767px) {
  .pagination {
    font-size: 18px;
    width: 100%;
    display: flex;
    justify-content: center; } }

.events-item {
  margin-bottom: 50px;
  display: flex;
  flex-direction: row;
  width: 100%; }
  @media screen and (max-width: 767px) {
    .events-item {
      flex-direction: column;
      width: 100%; } }
  .events-item__image {
    min-width: 16rem;
    min-height: 10rem;
    display: flex;
    align-items: center;
    justify-content: center; }
    .events-item__image img {
      max-width: 258px; }
      @media screen and (max-width: 767px) {
        .events-item__image img {
          max-width: 100%;
          width: 100%; } }
  .events-item__container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-left: 20px;
    width: 100%; }
    @media screen and (max-width: 767px) {
      .events-item__container {
        margin-left: 0;
        margin-top: 12px; } }
    .events-item__container__item-name {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 21px; }
    .events-item__container__item-description {
      font-weight: 400;
      font-size: 14px;
      line-height: 16.41px; }
    .events-item__container__item-props {
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
      @media screen and (max-width: 767px) {
        .events-item__container__item-props {
          flex-direction: column;
          margin-top: 20px; } }
      .events-item__container__item-props__author-info, .events-item__container__item-props__event-date {
        display: flex;
        border-right: 1px solid #959494;
        padding-right: 29px; }
        @media screen and (max-width: 767px) {
          .events-item__container__item-props__author-info, .events-item__container__item-props__event-date {
            border: 0;
            padding-right: 0;
            margin: 6px 0;
            justify-content: space-between; } }
      .events-item__container__item-props__author-info {
        align-items: center; }
        @media screen and (max-width: 767px) {
          .events-item__container__item-props__author-info {
            margin-bottom: 9px;
            justify-content: flex-start; } }
        .events-item__container__item-props__author-info-image {
          width: 40px;
          min-width: 40px;
          height: 40px;
          min-height: 40px;
          border-radius: 50%; }
          .events-item__container__item-props__author-info-image img {
            width: 100%;
            height: 100%;
            -o-object-fit: cover;
            object-fit: cover;
            border-radius: 50%; }
        .events-item__container__item-props__author-info-name {
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          color: #409DD9;
          margin-left: 29px; }
      .events-item__container__item-props__event-date, .events-item__container__item-props__publication-date {
        display: flex;
        align-items: center; }
        @media screen and (max-width: 767px) {
          .events-item__container__item-props__event-date, .events-item__container__item-props__publication-date {
            border: 0;
            padding-right: 0;
            margin: 6px 0;
            justify-content: space-between; } }
        .events-item__container__item-props__event-date span, .events-item__container__item-props__publication-date span {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          color: #424242; }
        .events-item__container__item-props__event-date span:last-child, .events-item__container__item-props__publication-date span:last-child {
          margin-left: 29px;
          color: #1293D4; }

.events-item:last-child {
  margin: 0; }

.main-page .events-list {
  padding: 20px 0; }

.main-page .events-item__container__item-props__author-info, .events-item__container__item-props__event-date {
  padding-right: 15px; }

.advantages-item {
  display: flex;
  flex-direction: row;
  background: #F4F4F4;
  width: 365px;
  margin: 0 100px 71px; }
  @media screen and (max-width: 767px) {
    .advantages-item {
      margin: 0 0 12px; } }
  .advantages-item__image {
    background: #FFFFFF; }
    .advantages-item__image img {
      max-width: 115px; }
  .advantages-item__container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: #F4F4F4;
    padding: 0 10px; }
    .advantages-item__container__item-name {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #424242; }

.registry-search__block {
  padding-bottom: 70px; }

.step-title {
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 41px;
  text-align: center; }

.step-nav > .nav-link > .nav-link-layout {
  box-shadow: 0px 4px 4px 1px #409dd9;
  border-radius: 15px; }

.step-nav > .nav-link > .link-heading {
  background: #FFFFFF;
  box-shadow: 0px 0px 4px 1px #409dd9;
  border-color: #d7dfed;
  border-width: 1px; }

.step-nav > .nav-link > .link-body {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #424242; }

.registry-search__block div:first-child .registry-module-block .accordion {
  border-radius: 10px 10px 0 0; }

.registry-search__block .registry-module-block .accordion {
  border: 1px solid #21299a;
  color: #21299a;
  font-size: 28px;
  font-weight: 700;
  width: 100%;
  padding: 24px;
  text-align: left;
  display: flex;
  justify-content: space-between; }

.registry-search__block .registry-module-block .accordion-panel {
  display: none;
  border: 1px solid #d7d7d7;
  background-color: #ffffff;
  padding: 32px 41px; }

.registry-search__block .registry-module-block .accordion-panel input {
  height: 56px;
  border-radius: 10px;
  border: 1px solid #d7d7d7;
  background-color: #ffffff;
  color: #585858;
  font-size: 16px;
  line-height: 32px; }

.registry-search__block .registry-module-block .accordion-panel input::placeholder {
  color: #585858;
  font-size: 16px;
  line-height: 32px; }

.registry-search__block .registry-module-block .accordion-panel label {
  display: none; }

.registry-search__block .registry-module-block .accordion-panel select {
  margin: 40px 0;
  height: 56px;
  border-radius: 10px 10px 0 0;
  border: 1px solid #d7d7d7;
  background-color: #ffffff;
  color: #585858;
  font-size: 16px;
  line-height: 32px; }

.registry-search__block .registry-module-block .accordion-panel select option {
  color: #585858;
  font-size: 16px;
  line-height: 32px; }

.registry-search__block .registry-module-block .accordion-panel .btn {
  max-width: 284px;
  padding: 12px 0; }

.news_content {
  margin-top: 2rem; }

.news_latest-list li {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-top: 1rem; }
  .news_latest-list li span {
    font-size: 1.3rem;
    font-weight: 500; }
  .news_latest-list li a {
    font-size: 1.3rem; }
    .news_latest-list li a:hover {
      color: #097db7; }
    .news_latest-list li a > h5 {
      margin: 0 !important; }

.company-advantages {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 32px; }

.advantages-title {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  color: #49546C; }

.advantages {
  display: flex;
  flex-direction: column;
  gap: 24px; }
  .advantages-item-container {
    display: flex;
    gap: 16px; }
    @media (max-width: 450px) {
      .advantages-item-container {
        gap: 12px; } }
    .advantages-item-container .advantages-icon {
      min-width: 48px;
      height: 48px; }
      @media (max-width: 450px) {
        .advantages-item-container .advantages-icon {
          min-width: 38px;
          height: 38px; } }
    .advantages-item-container .advantages-text-wrapper {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 7px;
      color: #49546C; }
      @media (max-width: 450px) {
        .advantages-item-container .advantages-text-wrapper {
          gap: 8px; } }
      .advantages-item-container .advantages-text-wrapper .subtitle {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase; }
      .advantages-item-container .advantages-text-wrapper .text, .advantages-item-container .advantages-text-wrapper .subtitle {
        color: #49546C;
        margin: 0;
        line-height: normal; }

.steps {
  padding: 20px;
  display: flex;
  justify-content: space-between; }
  .steps-title {
    padding-left: 20px;
    margin-top: 40px; }
  .steps > .step-container {
    flex: 1; }
  @media (max-width: 768px) {
    .steps {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 24px;
      margin-top: 20px; } }

.step-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  max-width: 185px; }

.step-icon {
  min-width: 96px;
  max-width: 96px;
  height: 96px; }
  @media (max-width: 768px) {
    .step-icon {
      min-width: 64px;
      max-width: 64px;
      height: 64px; } }

.step-title {
  color: #409DD9;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase; }

.step-description {
  color: #49546C;
  font-size: 16px;
  font-weight: 400;
  max-width: 150px; }

.step-submit-button {
  border-radius: 60px;
  background: #EE3F58;
  display: flex;
  width: 100%;
  padding: 10px;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  border: none;
  outline: none;
  color: #FFF; }

.step-title, .step-description {
  margin: 0;
  text-align: center;
  line-height: normal; }

.arrow {
  min-width: 27px;
  max-height: 12px;
  margin: 42px auto 0; }
  @media (max-width: 768px) {
    .arrow {
      display: none; } }
