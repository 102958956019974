.custom_tabs {
  .nav-tabs {
    padding-left: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    border-bottom: 1px solid #ddd;

    @media(max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }

    li {
      padding-left: 0;
      width: auto;
      min-width: 200px;
      flex-grow: 1;

      @media(max-width:768px) {
        width: 100%;
      }

      a {
        &:before {
          display: none;
        }
      }

      &.active {
        a,
        a:focus,
        a:hover {
          background-color: #f15169;
          padding-top: 5px;
          border-radius: 4px 4px 0 0;
          color: #555;
          cursor: default;

        }
      }
    }

    a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      text-align: center;
      padding: 5px 10px;
      border-bottom: 1px solid #f15169;
    }
  }

  .tab {
    &-content {
      &>.active {
        display: block;
      }
    }

    &-pane {
      display: none;
    }
  }
}

// accordeon

.accordeon {
  .panel{
    &-group {
      margin-bottom: 20px;

      .panel {
        margin-bottom: 0;
        border-radius: 4px;
        border-color: #ddd;
        background-color: #fff;
        border: 1px solid transparent;
        box-shadow: 0 1px 1px rgba(0,0,0,.05);

        &-heading {
          color: #333;
          background-color: #f5f5f5;
          border-color: #ddd;
          border-bottom: 0;
          padding: 10px 15px;
          border-top-left-radius: 3px;
          border-top-right-radius: 3px;
          cursor: pointer;
        }

        &-title {
          margin-top: 0;
          margin-bottom: 0;
          font-size: 16px;
          position: relative;

          &:after{
            font-family: icomoon !important;
            position: absolute;
            right: 0;
            content: $icon-arrow-down;
            transition: all 0.5s ease;
          }
        }

        &-body {
          border-top-color: #ddd;
          border-top: 1px solid #ddd;
          padding: 15px;
        }
      }
    }

    .active{
      .panel-title:after{
        transform: rotate(180deg);
      }
    }
  }

  .collapse {
    display: none;

    &.show {
      display: block;
    }
  }
}
