footer.footer {
  background: #0e1a35;
  color: #fff;
  margin-top: 20px;

  .bottom-section {
    background: url("/img/bg-formbottom.jpg") no-repeat 50% 50%;
    background-size: cover;

    p {
      color: #fff;
      font-size: 18px;
    }

    .wrap-formbs {
      max-width: 570px;
      margin: 0 auto;
      padding: 56px 10px;
      text-align: center;
    }

    form {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      > .form-group {
        width: 100%;
        max-width: calc(50% - 24px);
        margin: 0 12px;
        text-align: left;

        @media screen and (max-width: 768px) {
          max-width: 100%;
        }
      }

      label {
        color: #fff;
        font-size: 16px;
      }

      input,
      select {
        color: #fff;
        background: transparent;
        border: 1px solid #fff;
      }
    }

    .form-button {
      width: 100%;
    }

    .form-control option {
      background: rgba(239, 62, 88, .9);
    }

    .help-block {
      color: #f00 !important;
    }
  }

  .footer-bottom-menu {
    display: flex;
    align-items: center;
    padding: 25px 0;
    justify-content: space-between;
    flex-wrap: wrap;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      text-align: center;
    }

    &__left, &__left {
      display: flex;
      flex-direction: column;
      width: 37%;

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }

    .top-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__left {
      .top-content {
        margin-bottom: 35px;
      }

      .bottom-content > span {
        font-size: 10px;
        color: #f4f4f4;
        opacity: 0.6;
      }
    }

    &__right {
      .top-content {
        margin-bottom: 11px;

        @media screen and(max-width: 767px) {
          flex-direction: column;
        }
      }

      .bottom-content .hide-textm {
        font-size: 10px;
        font-weight: 700;
      }
    }


    .footer-callback,
    .footer-faq,
    .footer-news {
      a {
        font-weight: bold;
        font-size: 16px;
        color: #EF4F67;
        text-transform: uppercase;
      }
    }

    .footer-callback {
      @media screen and (max-width: 767px) {
        margin: 15px 0;
      }

      p {
        margin-bottom: 0;
        color: #F4F4F4;
        text-transform: uppercase;
        font-size: 10px;
      }

      .sitemap {
        font-size: 12px;
        font-weight: 400;
        color: #F4F4F4;
      }
    }

    .footer-socials {
      width: 98px;
      display: flex;
      justify-content: space-between;

      img {
        max-width: 40px;
      }

      a {
        max-width: 25px;
        max-height: 25px;
      }
    }

    .logo {
      width: 150px;
      height: 28px;
    }
  }
}