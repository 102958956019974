.header-search #mainSearch {
  margin-top: 25px;
  position: relative;

  .form-group {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 0;
  }

  .input-wrapper {
    position: relative;
    max-width: 975px;
    width: 100%;
    margin-right: 25px;
  }

  #js-search-main {
    height: 37px;
    border: 1px solid #7691c8;
    border-radius: 30px !important;
    color: #4a4a4a;
  }

  #js-search-main::placeholder {
    color: #4a4a4a;
  }

  #mainSearchSubmit {
    min-width: 135px;
    background-color: #f15169;

    .icon-search {
      position: relative;
      left: -10px;
    }
  }

  #js-search-main-scope {
    position: absolute;
    width: 100%;
    max-width: 975px;
    background: #fff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    z-index: 1;
  }

  @media (max-width: 767px) {
    display: none;
  }
}
