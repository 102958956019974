.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 50;
  display: none;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  background-color: rgba(0, 0, 0, .7);
  overflow: hidden;
  opacity: 0;
  transition: opacity .25s linear;
  height: 100dvh !important;

  &.in {
    opacity: 1;
  }

  &-dialog {
    max-width: 540px;
    margin: 30px auto;
    position: relative;

    &--reviews {
      margin-top: 30vh;
      max-width: 723px;
    }
  }

  &-content {
    background: #f4f4f4;
    padding: 25px 15px;
    border-radius: 9px;
    position: relative;
    box-shadow: 0 5px 15px rgba(0,0,0,.5);
    outline: 0;

    &-app {
      background-color: transparent;
      border-radius: 0;
      padding: 0;
    }

    &--reviews {
      background-color: #fff;
      width: 723px;
      padding: 34px 34px 18px 34px;
      border: 1px solid #ee3f58;
      border-radius: 0;

      .m-wrapp {
        max-width: 100% !important;
      }

      .form-group {
        margin-bottom: 16px;
      }

      .modal-title {
        font-weight: 400;
        text-align: left !important;
      }

      .modal-text {
        font-size: 17px;
      }

      .modal-body .modal--review {
        border: 1px solid #8F9395;
        font-size: 17px;
        border-radius: 0px;
      }

      .modal--review::placeholder {
        color: #8F9395;
      }

      .modal-footer {
        display: flex;
        gap: 16px;
        align-items: center;

        p {
          display: block;
          margin: 0;
          font-size: 14px;
          text-align: left;
          color: #000;
        }
      }

      button[type="submit"] {
        padding: 10px 34px;
        font-size: 16px;
      }

      .overlay__close {
        top: 10px;
        right: 10px;

        span:after,
        span:before {
          left: 6px;
          width: 14px;
          height: 1px;
        }
      }
    }
  }

  .m-wrapp {
    max-width: 330px;
    margin: 0 auto;
  }

  .modal-title {
    text-align: center;
    font-size: 24px;
  }

  .modal-border {
    border: 1px dotted #4e608d;
    border-radius: 8px;
    padding: 35px 20px 20px;
    text-align: center;
  }

  .modal-header {
    border-bottom: 0;
    padding: 0;
  }

  .modal-body {
    label {
      color: #747474;
      font-size: 14px;
      border-radius: 15px;
      margin-bottom: 5px;
      padding-top: 0;
      border: 0;
      display: block;
    }

    input {
      margin-bottom: 0;
      border-radius: 3px;
      padding: 10px;
      border: 0;
      background-color: #fff;
      color: #000;
    }

    textarea {
      width: 100%;
      max-width: 100%;
      border-radius: 3px;
      height: 122px;
      border: 0;
      background-color: #fff;
      margin-bottom: 0;
      color: #000;
    }

    .has-error {
      input,
      textarea {
        background-color: #ccc !important;
        border-radius: 3px;
      }
    }

    .field-contactform-recaptchacontactus,
    .field-contactform-recaptcha {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    .field-contactform-phone {
      margin-bottom: 12px;
    }

    .phone-tooltip {
      font-size: 14px;
    }
  }

  .modal-footer {
    border-top: 0;
    text-align: center;

    p {
      color: #a7a7a7;
    }
  }

  .btn-closex {
    top: 10px;
    right: 30px;
    background: transparent;
    border: 0;
    padding: 0;

    span {
      &:before,
      &:after {
        background: #fff;
      }
    }
  }
}

.overlay__close {
  position: absolute;
  top: 21px;
  right: 21px;
  width: 25px;
  height: 25px;
  opacity: 1 !important;
  padding: 0;
  background-color: transparent;
  border: 0;
  z-index: 200;
}

.overlay__close:hover {
  opacity: 0.7 !important;
}

.overlay__close span {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.overlay__close span:before,
.overlay__close span:after {
  content: "";
  width: 25px;
  height: 4px;
  background: #c7c7c7;
  display: block;
  border-radius: 1px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.overlay__close span:before {
  transform: rotate(45deg);
}

.overlay__close span:after {
  transform: rotate(-45deg);
}

.alert {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.6);

  &.alert-success {
    color: #008000;
  }

  &.alert-error {
    color: #ff0000;
  }

  .alert-message {
    position: absolute;
    background-color: #fff;
    padding: 30px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

#CalltouchWidgetFrame{

  @media screen and (max-width: 767px) {
    .hlSVWR{
      width: 100% !important;
      bottom: 0 !important;
    }

    .caVnsn{
      background: none !important;
    }
  }
}

//pop-up
.review-button-container {
  position: fixed;
  top: 380px;
  transform: rotate(-90deg) translateY(-70px);
}

@media (max-width: 992px) {
  .review-button-container {
    display: none;
  }
}

.review-button {
  padding: 10px 19px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
