.m-0 {
  margin: 0;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-10 {
  margin-top: 10px;
}

.mb5 {
  margin-bottom: 5px !important;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 100%;
}

.clear {
  clear: both
}

.fix {
  overflow: hidden
}

.text-center {
  text-align: center;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.color-red {
  color: #ef3e58 !important;
}
