.main-page {
  @media (max-width: 478px) {
    .item .ncat {
      display: none;
    }

    .item {
      text-align: center;
    }

    .descr-news {
      display: block;
    }

    .descr-news p {
      width: 100%;
      padding-left: 0;
    }
  }

  #get-expert-consultation-pjax {
    margin-top: 0;
    margin-bottom: 0;
    height: 100%;
    background: rgba(239, 62, 88, .9);
    padding: 40px 15px;

    @media (max-width: 768px) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .form-step2 {
    margin-top: 12px;
  }

  .banner {
    &_container {
      display: flex;
      justify-content: center;

      @media (max-width: 768px) {
        flex-wrap: wrap;
      }
    }

    &_text {
      width: 100%;
      min-width: 418px;
      background-color: #fff;
      height: inherit;
      padding: 30px 25px;

      @media (max-width: 768px) {
        width: 100%;
        min-width: inherit;
      }

      .title-saminormal {
        font-size: 36px;
        line-height: 40px;
        color: #ef3e58;
        font-weight: 700;
        margin-bottom: 40px;

        span {
          display: block;
          text-transform: uppercase;
          font-size: 36px;
          line-height: 40px;
          color: #ef3e58;
          font-weight: 700;
        }
      }

      p {
        font-size: 24px;
        font-weight: 700;
        color: #409dd9;

        &:last-child {
          color: #ef3e58;
        }
      }

      @media (max-width: 480px) {
        text-align: center;
      }
    }

    &_form {
      width: calc(40% - 30px);
      min-width: 280px;
      margin-left: 30px;

      @media (max-width: 768px) {
        width: 100%;
        min-width: inherit;
        margin-left: 0;
      }
    }
  }
}
