.events-item {
  margin-bottom: 50px;
  display: flex;
  flex-direction: row;
  width: 100%;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    width: 100%;
  }

  &__image {
    min-width: 16rem;
    min-height: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 258px;

      @media screen and (max-width: 767px) {
        max-width: 100%;
        width: 100%;
      }
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-left: 20px;
    width: 100%;

    @media screen and (max-width: 767px) {
      margin-left: 0;
      margin-top: 12px;
    }

    &__item-name {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 21px;
    }

    &__item-description {
      font-weight: 400;
      font-size: 14px;
      line-height: 16.41px;
    }

    &__item-props {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @media screen and (max-width: 767px) {
        flex-direction: column;
        margin-top: 20px;
      }

      &__author-info, &__event-date {
        display: flex;
        border-right: 1px solid #959494;
        padding-right: 29px;

        @media screen and (max-width: 767px) {
          border: 0;
          padding-right: 0;
          margin: 6px 0;
          justify-content: space-between;
        }
      }

      &__author-info {
        align-items: center;

        @media screen and (max-width: 767px) {
          margin-bottom: 9px;
          justify-content: flex-start;
        }

        &-image {
          width: 40px;
          min-width: 40px;
          height: 40px;
          min-height: 40px;
          border-radius: 50%;

          img {
            width: 100%;
            height: 100%;
            -o-object-fit: cover;
            object-fit: cover;
            border-radius: 50%;
          }
        }

        &-name {
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          color: #409DD9;
          margin-left: 29px;
        }
      }

      &__event-date, &__publication-date {
        display: flex;
        align-items: center;

        @media screen and (max-width: 767px) {
          border: 0;
          padding-right: 0;
          margin: 6px 0;
          justify-content: space-between;
        }

        span {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          color: #424242;
        }

        span:last-child {
          margin-left: 29px;
          color: #1293D4;
        }
      }
    }
  }
}

.events-item:last-child {
  margin: 0;
}

.main-page .events-list {
  padding: 20px 0;
}

.main-page .events-item__container__item-props__author-info, .events-item__container__item-props__event-date {
  padding-right: 15px;
}
