.service-menu {
  background: #fff;
  padding: 15px 0 10px 0;

  @media screen and(max-width:767px) {
    padding: 15px 0 30px 0;
  }

  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and(max-width:767px) {
      flex-direction: column;
    }
  }

  .menu-items {
    a:not(:last-child) {
      margin-right: 40px;
      @media (max-width:767px) {
        margin-right: 25px;
      }
    }

    a {
      color: #424242;
      font-size: 12px;

      &:hover {
        color: #669cd6;
      }
    }

    .legal-banking-services {
      display: none;

      @media (max-width:767px) {
        display: unset;
      }
    }

  }

  .service-items {
    a {
      font-weight: 600;
      font-style: italic;
      color: #f4f4f4;
      display: inline-block;
      font-size: 12px;

      > span {
        transform: skew(20deg);
        display: inline-block;
      }
    }

    .rect {
      padding: 10px 23px;
      background: #4b9bce;
      transform: skewX(-20deg);
    }

    @media screen and(max-width:767px) {
      display: none;
    }
  }
}
