table {
  display: block;
  overflow-x: auto;
  border-collapse: collapse;
  width: 100%;
  text-indent: 0;
  margin: 12px 0;
}

table td, table th {
  border: 1px solid #dcd9d9;
  padding: 8px 10px;
  color: #333333;
  font-size: 14px;
}

.table {
  display: table;
}

.table-cell {
  display: table-cell;
  vertical-align: middle;
  float: none;
}
