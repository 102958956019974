﻿:root {
  --typo-secondary-color: #1293D4;
  --typo-main-color: #424242;
}
.flex {
  &-direction {
    &-column {
      flex-direction: column;
    }
  }
}


/* Search form */
.search-form input,.search-form button{
  height: 45px;
  border-radius: 0;
}
.sidebar-widget {
  margin-bottom: 30px;
}
.blog-sidebar .sidebar-widget:last-child {
  margin-bottom: 0;
}

.sidebear-post-thumb {
  width: 100%;
  text-align: left;
  max-height: 150px;
  min-height: 70px;
  margin-bottom: 20px;
  overflow: hidden;
}

.categor-p .list-view>div .sidebear-post-thumb {
  max-width: 250px;
  position: relative;
  height: 150px;
  margin-right: 16px;
  width: 100%;
  text-align: left;
  max-height: 150px;
  margin-bottom: 20px;
  overflow: hidden;
  justify-content: center;
  flex-direction: column;
}

.article_content.categor-p .list-view>div .sidebear-post-thumb {
  height: 157px;
  max-height: 157px;
}

.categor-p .list-view {

  .article_author {
    justify-content: flex-start;

    .author_content {
      margin-right: 10px;
    }

    .author_info {
      min-width: auto;
    }

    .article_publication > p {
        margin-left: 10px;
    }

    .link-readmore {
      position: relative;
      margin-left: auto;
      align-self: flex-end;

      @media only screen and (max-width: 768px) {
        margin: 15px auto 0 5px;
      }
    }
  }
}

.categor-p .list-view > div .sidebar-postcontent {
  width: 100%;
}

.categor-p .list-view>div .title-exparticle {
  text-align: left;
}

.categor-p .list-view>div .title-exparticle a {
  font-size: 18px;
}

.categor-p .list-view>div .sidebear-post-thumb a,
.categor-p .list-view>div .sidebear-post-thumb img {
  width: 100%;
  height: 100%;
  display: block;
  -o-object-fit: cover;
  object-fit: cover;
}

.sidebar-widget .title-saminormal a{
  text-transform: capitalize;
}

.tab-content {
  padding: 20px 40px;
  background-color: #f4f4f4;
}

.tab-style3 .tab-content {
  background-color: transparent;
  padding: 10px 0;
}
/*---------- accordion -------------*/
.panel > .panel-heading{
  display: block;
}

.form-messege.error {
  border: 2px solid #d35400;
  color: #d35400;
  margin-top: 10px;
  padding: 4px;
}
.form-messege.success {
  border: 2px solid #0b9444;
  color: #0b9444;
  margin-top: 10px;
  padding: 4px;
}
.li-without-marker {
  list-style-type: none;
  margin-top: 10px;
}

.cat-text {
  text-align: justify;
}

#comment-form .field-commentmodel-username input {
  padding: 5px 10px;
}

#comment-form textarea {
  width: 100%;
  max-width: 100%;
  background: #fff;
  border: 1px solid #dcd9d9;
}

.main-header-wrap {
  display: flex;
  align-items: center;
}

a {
  text-transform: none;
}

.sidebar-widget .title-saminormal a {
  text-transform: none;
}

.pagination .page-list li a,
.pagination .page-list li span {
  border-radius: 3px;
}

.search-form input,
.search-form button {
  height: 37px;
}

#main-block {
  min-height: 80%;
  min-width: 320px;
}

#articles-page {
  .list-inline {
    > li {
      > div {
        padding: 10px;
      }
    }
  }
}

.pointer {
  cursor: pointer;
}

header {
  position: relative;
}

.sidebar-postlists .sidebar-postcontent .title-saminormal {
  margin-bottom: 5px;
}

.sidebar-postlists .sidebar-postcontent .anno {
  margin-top: 5px;
}

#news-page {
  .news-list {
    & > li  {
      padding: 10px;
      width: 100%!important;
      display: flex;
      position: relative;
      background: #ffffff;

      @media screen and (max-width: 576px) {
        flex-direction: column;
        align-items: center;
      }

      .head-ar {
        position: absolute;
        right: 10px;
        bottom: 10px;
      }

      .sidebear-post-thumb {
        max-width: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        padding: 10px;

        img {
          max-width: 100%;
          height: auto;
        }
      }

      .sidebar-postcontent {
        .title-exparticle {
          text-align: left;
        }

        .text-primary {
          padding: 0;
        }

        .link-readmore {
          left: 0;
        }
      }
    }
  }
}
.news-section {
  border-radius: 4px;
  padding-bottom: 10px;
}

.news-section .sidebar-postlists li {
  background: #fff;
  border-radius: 4px;
  padding: 10px;
}

.news-list .item {
  background: #eee;
  height: 100%;
}

ul.news-list {
  flex-direction: column;
}

#ui-datepicker-div {
  z-index: 10 !important;
}

@media only screen and (max-width: 480px) {
  .tel-form {
    padding: 40px 5px;
  }

  .col-lg-4.col-xs-12.pt30.tf-article,
  .col-md-12.col-md-6.col-lg-5.col-xs-12 {
    overflow: hidden;
    padding: 0;
  }
}

ul.tree, ul.tree ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

ul.tree {
  margin-bottom: 20px;
}

ul.tree ul {
  margin-left: 40px;
}

ul.tree li {
  padding: 5px 12px;
}

ul.tree li div span {
  font-size: 14px;
  display: inline-block;
  margin-left: 20px;
}

ul.tree > li {
  margin: 0 0 10px;
  padding: 0 12px;
  line-height: 26px;
  font-size: 18px;
  color: #669cd6;
  font-weight: bold;
}

ul.tree > li a {
  color: #669cd6 !important;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 26px;
  vertical-align: middle;
}

ul.tree li span {
  color: #669cd6 !important;
  font-size: 1.2em;
  vertical-align: middle;
  margin-right: 5px;
}

ul.tree li a {
  color: #3288df !important;
  font-weight: 400;
}

ul.tree ul.pagination li {
  background: none;

}

ul.tree > div:last-child {
  background-color: #fff;
}

.top-nblock {
  color: #4b4b56;
  margin-bottom: 8px;
}

.ncat {
  color: #4b4b56;
  background: #dddee1;
  padding: 2px 13px 0;
  border-radius: 10px;
  margin-right: 10px;
  margin-bottom: 5px;
}

.ndiscription h5 {
  color: #5284ff;
  font-size: 18px;
  font-weight: 700;
}

.descr-news {
  color: #8492af;
  display: flex;
}

.descr-news a {
  color: #8492af;
}

.descr-news .author-rimg {
  display: inline-block;
  width: 25%;
  padding: 0 10px;

}

.descr-news .author-rimg img {
  border-radius: 50%;
  width: 100%;
}

.descr-news p {
  width: 75%;
  padding-left: 10px;
}

.mh-text p {
  font-weight: 700;
  color: #99aad1;
  font-size: 18px;
}

.mh-text h3 {
  color: #3e6aca;
  font-size: 30px;
  font-weight: 700;
}

.btn-closex {
  position: absolute;
  top: 21px;
  right: 21px;
  width: 25px;
  height: 25px;
  opacity: 1 !important;
}

.btn-closex:hover {
  opacity: 0.7 !important;
}

.btn-closex span {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.btn-closex span:before,
.btn-closex span:after {
  content: "";
  width: 25px;
  height: 4px;
  background: #c7c7c7;
  display: block;
  border-radius: 1px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.btn-closex span:before {
  transform: rotate(45deg);
}

.btn-closex span:after {
  transform: rotate(-45deg);
}


.rc-anchor-normal {
  width: 100% !important;
}

.list-height {
  display: flex;
  flex-wrap: wrap;
}

.list-height li {
  background-clip: content-box;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-bottom: 0;
}

.list-height li article {
  padding: 10px;
}
.content-wrapper {
  overflow: hidden;
}

.article__main-col {
  background-color: #fff;
}

.content-wrapper .h3 {
  color: #000;
  font-size: 35px;
  font-weight: 400;
  margin-bottom: 15px;
}

.has-error input {
  background: transparent !important;
}

.title-exparticle {
  color: #000;
  font-size: 20px;
  text-align: center;
  margin-bottom: 15px;
}

.sidebar-postcontent img {
  width: 100%;
  height: auto;
}

.tree span.counter {
  display: inline-block !important;
  vertical-align: top !important;
  background: #3e6aca !important;
  color: #fff !important;
  border-radius: 10px !important;
  padding: 2px 3px 1px !important;
  font-size: 9px !important;
  margin-top: 3px !important;
  margin-left: 3px !important;
  line-height: 10px;
}

.form-group input,
.form-group select {
  border-radius: 3px !important;
}

.btn-pull-left {
  float: left;
}

.list-tabs .item {
  margin-bottom: 30px;
}

.tab-content .list-height {
  background: transparent;
}

.tel-form {
  background: rgba(239, 62, 88, .9);
  padding: 40px 15px;
  margin-bottom: 20px;

  @media (max-width: 991px) {
    margin-top: 20px;
  }

  @media (max-width: 767px) {
    margin-right: 15px;
    margin-left: 15px;
  }
}

.tel-form p {
  font-size: 16px;
  color: #fff;
  padding-left: 5px;
  padding-right: 5px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.form-titletext {
  text-align: center;
}

.tel-form select option {
  background: rgba(239, 62, 88, .9);
}

#getdetailsconsultationform-comment {
  min-height: 120px;
}

.tel-form label {
  color: #fff;
  font-size: 16px;
  position: relative;
}

.tel-form input[type=checkbox]:before {
  content: "";
  display: inline-block;
  border: 1px solid #fff;
  border-radius: 2px;
  width: 15px;
  height: 15px;
  background-color: #f05068;
  position: absolute;
  left: 0;
  right: 0;
  top: -5px;
  bottom: 0;

  @media (max-width: 767px) {
    display: none;
  }
}

.tel-form input[type=checkbox]:checked:after {
  content: "\2714";
  display: inline-block;
  vertical-align: top;
  font-size: 9px;
  font-weight: bold;
  text-align: center;
  border: 1px solid #fff;
  border-radius: 2px;
  color: #fff;
  width: 15px;
  height: 15px;
  background-color: #f05068;
  position: absolute;
  left: 0;
  right: 0;
  top: -5px;
  bottom: 0;
  z-index: 2;

  @media (max-width: 767px) {
    display: none;
  }
}

.tel-form #getdetailsconsultationform-agree,
.tel-form #getexpertconsultationform-agree {
  top: -4px;

  @media (max-width: 767px) {
    top: -6px;
  }
}

.tel-form .field-getdetailsconsultationform-agree label,
.tel-form .field-getexpertconsultationform-agree label {
  font-size: 9px;
  top: 3px;
}

.forms-text {
  font-weight: 700;
  text-transform: none !important;
}

.smt-form {
  color: #ffc5ce !important;
  font-size: 16px;
  line-height: 1.2em;
  text-transform: none !important;
}

.box-info .control-label {
  color: #747474;
  font-size: 14px;
  display: block;
  text-align: left;
}

.box-info .form-control {
  margin-bottom: 24px;
  border: 1px solid #dcdcdc;
  border-radius: 3px;

}

.h3okno {
  font-size: 20px;
  color: #000;
  padding-left: 30px;
  position: relative;
  font-weight: 400;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.h3okno:before {
  content: "";
  width: 4px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background: #669cd6;
}

.head-ar {
  margin-bottom: 10px;
}

.head-ar .title {
  color: #669cd6;
  font-size: 16px;
  text-decoration: underline;
}

.article-twr {
  padding-bottom: 28px;
  position: relative;
}

.article-twr p.text-primary {
  padding: 10px 45px 10px 15px;
  position: relative;
  background: #fff;
  overflow: hidden;

  @media screen and (max-width:767px) {
    padding: 0;
  }
}

.article-twr p.text-primary a {
  color: #747373;
  font-size: 14px;

}

.article-twr p.text-primary:before {
  content: "";
  position: absolute;
  bottom: -20px;
  right: 20%;
  border-top: 20px solid #f4f4f4;
  border-right: 0 solid transparent;
  border-left: 24px solid transparent;

}

.link-readmore {
  position: absolute;
  bottom: 5px;
  left: 5px;
}

.link-readmore a {
  color: #e7445e;
}

.link-readmore a:hover {
  opacity: 0.8;
}


.bottom-section select,
.form-group select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 7% !important;
  background: url("/img/arr-down.png") no-repeat 97% 50% !important;
}

.form-section .help-block {
  color: #fff !important;
}

.direction li {
  margin-bottom: 20px;
}

.direction li a:hover {
  text-decoration: none;
}

.direction-inline {
  margin-bottom: 30px;
}

.direction-inline ul {
  text-align: center;
}

.direction-inline ul li {
  text-align: center;
  width: 20%;
  padding: 0 30px;
  display: inline-block;
  vertical-align: top;
  margin-right: -4px;
}

.formflex form {
  display: flex;
  justify-content: flex-end;
}

.formflex form button {
  height: 32px;
}

div.empty {
  margin-top: 10px !important;
  margin-left: 5px !important;
}

.categor-p {
  background-color: #fff;
  padding-left: 35px;
  padding-right: 30px;
}

.categor-p .list-view > div {
  margin-right: -4px;
  width: 100%;
  display: flex;
  padding-top: 42px;

  @media screen and (max-width:767px) {
    flex-direction: column;
  }
}

.categor-p .list-view > div.pagination {
  flex-direction: row;
}

ul.tree li span.js-expanded {
  color: #f71336 !important;
}

ul.tree span.js-expanded + a.codes-tree {
  color: #f71336 !important;
}

.tree-node {
  background-color: #f4f4f4;
  overflow: hidden;
}

ul.tree-node > li a,
ul.tree-node li span {
  color: #669cd6 !important;
}

#js-search-main-scope {
  display: inline-block;
}

ul {
  margin: inherit;
  padding: inherit;
}

li {
  list-style-type: inherit;
}

.faq h3 {
  margin: 20px 0;
}

.banner-text-red {
  color: #f15169;
  font-size: 24px;
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  .expert-subscription-form .row {
    margin: 0;
  }
}



.tf-article .tel-form {
  background: rgba(239, 62, 88, 1);
}

.select2-selection__clear {
  font-size: 14px !important;
  cursor: pointer;
}

.select2-search__field {
  width: 100% !important;
}

.faq p {
  text-align: justify;
}

.faq .docs{
  text-align: center;

  @media screen and (min-width:768px) {
    display: flex;
    justify-content: space-between;
  }

  img {
    margin-bottom: 25px;

    @media screen and (min-width:768px) {
      max-width: 30%;
    }
  }
}

.categories__list {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  margin-left: -20px;
  margin-right: -20px;
}

.category-item {
  margin-bottom: 60px;
  width: 50%;
  padding-left: 30px;
  padding-right: 30px;

  @media (max-width: 767px) {
    width: 100%;
    padding: 0;
  }

  &_expand {
    width: 80%;
    left: 50%;
    position: relative;
    transform: translateX(-50%);

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  &__inner {
    display: flex;
    align-items: center;
    background-color: #f4f4f4;
  }

  &__img-box {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid #409dd9;
    flex-shrink: 0;
    background-color: #fff;

    img {
      max-width: 60px;
      height: auto;
    }
  }

  &__label {
    color: #333;
    font-size: 16px;
    line-height: 24px;
    margin-left: 10px;
    margin-right: 10px;
    letter-spacing: -0.5px;
    margin-bottom: 0;
    font-weight: 500;
    padding-top: 15px;
    padding-bottom: 15px;

    @media (max-width: 767px) {
      font-weight: 400;
    }

    &_expand {
      color: #f15168;
      font-size: 16px;
      line-height: 24px;
      text-transform: uppercase;
      display: flex;
      align-items: center;

      @media (max-width: 767px) {
        display: block;
        text-align: center;
      }
    }
  }
}

[data-collapse-button] {
  cursor: pointer;
  margin-top: 30px;
}

ul:not([class]) {
  list-style: none;
  margin-left: 0;
  padding-left: 0;

  li {
    padding-left: 1em;
    text-indent: -1em;
  }

  li:before {
    content: "";
    display: inline-block;
    background: url("/img/ul-dot.png") center/contain no-repeat;
    width: 14px;
    height: 14px;
    margin-right: 5px;
  }
}

.chosen-categories-block {
  border: 3px solid #669cd6;
  padding: 5px;
  margin-top: 30px;

  @media (max-width: 1199px) {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.chosen-categories {
  &__title {
    color: #ff3d58;
    letter-spacing: -0.3px;
    text-transform: uppercase;
    font-size: 15px;
  }

  &__list {
    list-style-type: none;
  }

  &-list__item {
    margin-bottom: 5px;
    cursor: pointer;
  }
}

.check-button-disabled [type=submit] {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  box-shadow: none;
  opacity: .65;
  pointer-events: none;

  &:hover {
    color: inherit;
  }
}

.mobile-expert-widget {
  display: none;

  @media (max-width: 767px) {
    display: block;
  }
}

.certification .tel-form {
  @media (max-width: 767px) {
    display: none;
  }
}

#articles-page .sidebar-postlists > li {
  background-color: #fff;
}

.select2-container--krajee .select2-selection {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #555555;
  font-size: 14px;
  outline: 0;
}

.select2-container--krajee .select2-selection {
  background: 0 0!important;
}


.sitemap-block {
  margin-top: 20px;

  &:last-child{
    margin-bottom: 25px;
  }

  ul, li {
    margin: 20px 0;
  }

  a{
    line-height: 2;
  }
}

.tel-form .personal-data-processing {
  margin-top: 22px;
}

.tel-form .personal-data-processing .personal-data-processing--text {
  font-size: 11px;
  color: white;
}

.tel-form .personal-data-processing .personal-data-processing--link {
  font-size: 11px;
  text-decoration: underline;
  color: white;
}

#team-page {
  padding-top: 20px;
  .pagination {
    margin-bottom: 9px;
  }

  .content-wrapper {
    background: #fff;
    padding: 0 20px;

    @media screen and (max-width: 767px) {
      padding: 0 10px;
    }
  }

  .team-list {
    background: #fff;
    display: flex;
    justify-content: space-between;
  }
}

#events-page {
  padding-top: 20px;

  .content-wrapper {
    background: #fff;
    padding: 0 20px;

    @media screen and (max-width: 767px) {
      padding: 0 10px;
    }
  }

  .events-list {
    padding: 20px 0;

    @media screen and (max-width: 767px) {
      padding: 10px 0 20px 0;
    }
  }
}

#advantages {
  margin: 50px 0;

  .content-wrapper {
    background: #fff;
    padding: 17px 5px;

    .h2okno {
      font-style: normal;
      font-weight: 500;
      font-size: 35px;
      line-height: 41px;
      text-align: center;
      margin-bottom: 50px;
      margin-top: 20px;
      color: #424242;


      @media screen and (max-width: 767px) {
        font-size: 28px;
        line-height: 33px;
      }
    }

    .advantages-grid {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    @media screen and (max-width: 767px) {
      padding: 0 10px;
    }
  }
}
