// select the svg element
$svg: "";
// select the path
$path: "";
// for each radio
@for $i from 1 through 5 {
  // for each valid star
  @for $x from 1 through $i {
    // add checked input to selectors
    $svg: $svg + "#star-#{$i}:checked ~ ";
    $path: $path + "#star-#{$i}:checked ~ ";
    // add svg or path element sib to selectors
    // scope = section label svg [path]
    $svg: $svg + "section [for='star-#{$x}'] svg";
    $path: $path + "section [for='star-#{$x}'] svg path";
    // if not the last, we add a comma
    @if $x != $i {
      $svg: $svg + ", ";
      $path: $path + ", ";
    }
  }
  // if not the last, we add the comma
  @if $i != 5 {
    $svg: $svg + ", ";
    $path: $path + ", ";
  }
}

// style the active svg
#{$svg} {
  transform: scale(1);
}
// style the active path
#{$path} {
  fill: #FFBB00;
  stroke: darken(#FFBB00, 10%);
}

section.star-body {
  width: 300px;
  text-align: center;
  position: absolute;
  top: 50%; left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

label.label-star {
  display: inline-block;
  width: 50px;
  text-align: center;
  cursor: pointer;
  svg {
    width: 100%;
    height: auto;
    fill: white;
    stroke: #CCC;
    transform: scale(0.8);
    transition: transform 200ms ease-in-out;
    path {
      transition: fill 200ms ease-in-out,
      stroke 100ms ease-in-out;
    }
  }
}

label[for="star-null"] {
  display: block;
  margin: 0 auto;
  color: #999;
}

